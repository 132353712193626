import { ReactNode, useEffect, useState } from "react";

import { ReactComponent as NotFoundIco } from "assets/icons/not-found-error-ico.svg";
import { ReactComponent as NotAuthorizedIco } from "assets/icons/not-authorized-error-ico.svg";
import { ReactComponent as InternalServerErrorIco } from "assets/icons/internal-server-error-ico.svg";
import { ReactComponent as CustomErrorIco } from "assets/icons/custom-error-ico.svg";
import { ReactComponent as AlertIco } from "assets/icons/info-ico.svg";
import { ReactComponent as BigAlertIco } from "assets/icons/error-triangle.svg";

import Modal from "../Modal/Modal";
import Button from "../Button/Button";
import CreatePortal from "../CreatePortal/CreatePortal";

import { useAppDispatch } from "redux/reduxTypes";
import { errorType } from "common/types/reducer.types";
import { setToast } from "redux/alert";
import { clearGlobalErrors } from "redux/global";

const ErrorHandling = ({
  error,
  contentClassName,
}: {
  error: errorType;
  modal?: boolean;
  cleanUpFunction?: () => void;
  contentClassName?: string;
}) => {
  const dispatch = useAppDispatch();

  const [closeModal, setCloseModal] = useState(false);

  const handleModal = () => {
    setCloseModal(true);
    dispatch(clearGlobalErrors());
  };

  const mainContentError = (Icon: ReactNode, title: string, text: string) => {
    return (
      <div
        className={`flex items-center bg-white justify-center w-full h-full py-6 rounded-xl ${contentClassName ? contentClassName : null}`}
      >
        <div className="flex flex-col items-center justify-cente text-center gap-6 max-w-[400px]">
          {Icon}
          <h2 className="text-[22px] text-black font-medium">{title}</h2>
          <p className="text-base text-light-gray font-normal">{text}</p>
        </div>
      </div>
    );
  };

  const modalError = (title: string, text: string) => {
    return (
      <div className="flex flex-col gap-4 items-center py-4 px-8">
        <div className="rounded-full bg-ultra-light-gray p-4">
          <BigAlertIco className="w-[50px] h-auto mb-1.5" />
        </div>
        <h2 className="text-[22px] text-black font-medium">{title}</h2>
        <p className="text-base font-normal max-w-[500px] text-center">
          {text}
        </p>
      </div>
    );
  };

  const formError = (text: string) => {
    return (
      <div className="flex items-center justify-cente text-left gap-2 w-fit py-2 px-3 rounded-xl bg-invalid-dimmed-red text-invalid-red">
        <AlertIco className="[&_path]:fill-[#A53B42] rotate-180" />
        <p className="text-base font-normal max-w-[500px] text-center">
          {text}
        </p>
      </div>
    );
  };

  const getErrorContent = (): ReactNode => {
    switch (error.status) {
      case 400:
        return error.style === "MAIN_CONTENT" ? (
          mainContentError(
            <CustomErrorIco />,
            "Oops, something went wrong",
            `${error.message}`,
          )
        ) : error.style === "INFO_BOX" ? (
          formError(`${error.message}`)
        ) : error.style === "MODAL" ? (
          modalError("Oops, something went wrong", `${error.message}`)
        ) : (
          <></>
        );

      case 401:
        return error.style === "MAIN_CONTENT" ? (
          mainContentError(
            <CustomErrorIco />,
            "Oops, something went wrong",
            `${error.message}`,
          )
        ) : error.style === "INFO_BOX" ? (
          formError(`${error.message}`)
        ) : error.style === "MODAL" ? (
          modalError("Oops, something went wrong", `${error.message}`)
        ) : (
          <></>
        );

      case 403:
        return error.style === "MAIN_CONTENT" ? (
          mainContentError(
            <NotAuthorizedIco />,
            "You are not authorized to view this content",
            "If you think there's been a mistake, contant your company admin.",
          )
        ) : error.style === "INFO_BOX" ? (
          formError("You are not authorized to do this action.")
        ) : error.style === "MODAL" ? (
          modalError(
            "Not Authorized",
            "You are not authorized to do this action.",
          )
        ) : (
          <></>
        );

      case 404:
        return error.style === "MAIN_CONTENT" ? (
          mainContentError(
            <NotFoundIco />,
            "Not found",
            "The content you are looking for is either currently unavailable or it doesn't exist.",
          )
        ) : error.style === "INFO_BOX" ? (
          formError("This action is currently unavailable.")
        ) : error.style === "MODAL" ? (
          modalError("Not Found", "This action is currently unavailable.")
        ) : (
          <></>
        );

      case 500:
        return error.style === "MAIN_CONTENT" ? (
          mainContentError(
            <InternalServerErrorIco />,
            "Oops, something went wrong",
            "Internal server error, we're on it.",
          )
        ) : error.style === "INFO_BOX" ? (
          formError("Oops, internal server error, something went wrong.")
        ) : error.style === "MODAL" ? (
          modalError("Oops, something went wrong", "Internal server error")
        ) : (
          <></>
        );

      default:
        return error.style === "MAIN_CONTENT" ? (
          mainContentError(
            <CustomErrorIco />,
            "There was an error",
            "Please try again later.",
          )
        ) : error.style === "INFO_BOX" ? (
          formError("There was an error. Please try again later.")
        ) : error.style === "MODAL" ? (
          modalError(
            "Oops, something went wrong",
            "There was an error. Please try again later.",
          )
        ) : (
          <></>
        );
    }
  };

  // -----------------------------------------------------------------

  const getToastBarMessage = (): string => {
    switch (error.status) {
      case 400:
        return `${error.message}`;
      case 401:
        return `${error.message}`;
      case 403:
        return "Not Authorized";
      case 404:
        return "Not Found";
      case 500:
        return "Internal server error";
      default:
        return "There was an error";
    }
  };

  useEffect(() => {
    if (error.style === "TOAST") {
      dispatch(setToast({ message: getToastBarMessage(), type: "error" }));

      setTimeout(() => {
        dispatch(clearGlobalErrors());
      }, 100);
    }
    setCloseModal(false);
  }, [error]);

  return error.style !== "TOAST" ? (
    <>
      {error.style === "MODAL"
        ? !closeModal && (
            <CreatePortal>
              <Modal close={handleModal}>
                <>{getErrorContent()}</>
                <Button
                  type="Primary"
                  text="Okay"
                  onClick={handleModal}
                  className="mx-auto w-1/2 rounded-xl mt-2"
                />
              </Modal>
            </CreatePortal>
          )
        : getErrorContent()}
    </>
  ) : (
    <></>
  );
};

export default ErrorHandling;
