import HiringFlowModules from "../../components/HiringFlowModules/HiringFlowModules";
import { Outlet, useLocation, useParams } from "react-router";
import FiltersSideBar from "../../../../components/FiltersSideBar/FiltersSideBar";
import { renderModuleName } from "../../../../../common/helpers/functions.helpers";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../redux/reduxTypes";
import { ReactComponent as UserIcoRed } from "assets/icons/user-red-ico.svg";
import { ReactComponent as UserIco } from "assets/icons/user-orange-ico.svg";
import { ReactComponent as CsvIco } from "assets/icons/csv-ico.svg";
import { ReactComponent as PdfIco } from "assets/icons/pdf-export-ico.svg";
import { ReactComponent as DocIco } from "assets/icons/document-big-ico.svg";
import { useAuthorization } from "../../../../../common/hooks/useAuthorization";
import { Fragment, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  exportPdfReportsCandidateList,
  exportPositionCandidateList,
} from "../../reducer/position.actions";
import { functionOptionType } from "../../../../components/CustomDropdown/CustomDropdown";
import CreatePortal from "../../../../components/CreatePortal/CreatePortal";
import Modal from "../../../../components/Modal/Modal";
import Spinner from "../../../../components/spinners/Spinner";
import ImportApplicantsForm from "../../components/ImportApplicantsForm/ImportApplicantsForm";
import { FaCheck } from "react-icons/fa";
import Button from "../../../../components/Button/Button";
import { clearExportCandidateList } from "../../reducer/position.reducer";
import { isObjEmpty } from "../../../../../common/utility/Utils";
import CandidateListFilters from "../../components/CandidateListFilters/CandidateListFilters";

const PositionTrackingWrapper = () => {
  const module = useAppSelector((state) => state.position.module?.value);
  const key = useParams();
  const position = useAppSelector((state) => state.position.positionOverview);
  const location = useLocation();
  const exportCandidateList = useAppSelector(
    (state) => state.position.exportCandidateList,
  );
  const { checkPermissions } = useAuthorization();
  const dispatch = useAppDispatch();
  const [importAppModal, setImportAppModal] = useState(false);
  const [downloadingReportsModal, setDownloadingReportsModal] = useState(false);
  const [isReportsRedirectedToEmailModal, setIsReportsRedirectedToEmailModal] =
    useState(false);
  const [isReportsDownloadLinkReady, setIsReportsDownloadLinkReady] =
    useState(false);
  const [exportAllPDFReportsClicked, setExportAllPDFReportsClicked] =
    useState(false);
  const [downloadingCVSModal, setDownloadingCSVModal] = useState(false);

  const candidateList = useAppSelector(
    (store) => store?.position.positionCandidateList,
  );

  const candidateListCount = useAppSelector(
    (store) => store?.position.positionCandidateList?.value?.states,
  );
  const globalFilters = useAppSelector(
    (state) => state.globalFilters[location.pathname],
  );

  const navigate = useNavigate();

  const sumState = useMemo(() => {
    if (candidateListCount) {
      return Object.values(candidateListCount).reduce(
        (acc: any, currentValue) => acc + currentValue,
        0,
      );
    } else {
      return 0;
    }
  }, [candidateListCount]);

  const isEmpty = (value: any): boolean => {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else if (typeof value === "string") {
      return value.trim() === "";
    } else {
      return value === null || value === undefined;
    }
  };

  // Generic function to check if at least one property is not empty
  const hasNonEmptyProperty = <T,>(obj: T): boolean => {
    if (!obj) return false;
    return Object.values(obj as any).some((value) => !isEmpty(value));
  };

  const exportFunction = (type: string) => {
    if (key.id && key.stepId) {
      setDownloadingCSVModal(true);

      dispatch(
        exportPositionCandidateList({
          stepId: key.stepId,
          id: key.id,
          type: type,
          paramsArray: globalFilters,
        }),
      )
        .then((res) => {
          const url = window.URL.createObjectURL(
            new Blob([res.payload as any]),
          );

          const link = document.createElement("a");
          link.href = url;
          link.download = position.value?.name
            ? `${position.value.name.toLowerCase().replace(/ /g, "-")}.csv`
            : "blank.csv";

          document.body.appendChild(link);

          link.click();
          link.remove();

          setDownloadingCSVModal(false);
        })
        .catch((error) => console.log(error));
    }
  };

  const exportPdfReports = (type: string) => {
    if (key.id && key.stepId) {
      setDownloadingReportsModal(true);
      setExportAllPDFReportsClicked(true);
      dispatch(
        exportPdfReportsCandidateList({
          id: key.id,
          stepId: key.stepId,
          type: type,
          paramsArray: globalFilters,
        }),
      );
    }
  };

  const totalData = useMemo(() => {
    return candidateList?.value?.paginatedData?.total;
  }, [candidateList?.value?.paginatedData?.total]);

  const totalWithoutFilter = useMemo(() => {
    return candidateList?.value?.paginatedData?.totalWithoutFilter;
  }, [candidateList?.value?.paginatedData?.totalWithoutFilter]);

  const checkHasFilter = useMemo(() => {
    const updatedData: any = { ...globalFilters?.filters };
    delete updatedData.sort;

    return (
      hasNonEmptyProperty(updatedData || null) ||
      hasNonEmptyProperty(globalFilters?.filters || null) ||
      globalFilters?.search?.length
    );
  }, [globalFilters]);

  const dropdownOptions: functionOptionType[] = useMemo(() => {
    const baseOptions = [
      {
        Icon: CsvIco,
        label: "Export All Results",
        functionOption: () => exportFunction("all"),
        disabled: totalWithoutFilter === 0 || totalWithoutFilter > 1000,
        tooltipDisabled:
          totalWithoutFilter > 1000
            ? "Export to CSV is limited to a maximum of 1000 applicants."
            : "No results",
        countNumber: totalWithoutFilter || 0,
      },
      {
        Icon: CsvIco,
        label: "Export Filtered Results",
        functionOption: () => exportFunction("filtered"),
        disabled: totalData === 0 || totalData > 1000,
        tooltipDisabled:
          totalData > 1000
            ? "Export to CSV is limited to a maximum of 1000 applicants."
            : "0 results for matching criteria",
        countNumber: totalData | 0,
        hide: totalData === totalWithoutFilter && !checkHasFilter,
      },
      {
        Icon: PdfIco,
        label: "Export All Reports",
        functionOption: () => exportPdfReports("all"),
        disabled: totalWithoutFilter === 0,
        tooltipDisabled: "No results",
        countNumber: totalWithoutFilter || 0,
      },
      {
        Icon: PdfIco,
        label: "Export Filtered Reports",
        functionOption: () => exportPdfReports("filtered"),
        disabled: totalData === 0,
        tooltipDisabled: "0 results for matching criteria",
        countNumber: totalData | 0,
        hide: totalData === totalWithoutFilter && !checkHasFilter,
      },
    ];

    const importOptions = [
      {
        borderTop: true,
        Icon: UserIco,
        label: "Import Candidates (CSV)",
        functionOption: () => setImportAppModal(true),
        hide:
          Number(key.stepId) !== 0 ||
          position.value.state === "ARCHIVED" ||
          !checkPermissions("MANAGE_POSITION_APPLICANTS"),
      },
      {
        Icon: UserIcoRed,
        label: "Import Resumes (PDF)",
        functionOption: () =>
          navigate(
            `/${position?.value?.company?._id}/position/${key.id}/settings/import-resumes`,
          ),
        hide:
          Number(key.stepId) !== 0 ||
          position.value.state !== "ACTIVE" ||
          !checkPermissions("MANAGE_POSITION_APPLICANTS") ||
          !checkPermissions("RESUME_IMPORT"),
      },
    ];

    return baseOptions.concat(importOptions as any);
  }, [sumState, globalFilters, totalData, totalData]);

  useEffect(() => {
    if (!exportCandidateList?.loading) {
      setDownloadingReportsModal(false);
    }
  }, [exportCandidateList?.loading]);

  useEffect(() => {
    if (exportCandidateList?.value?.isAsync && exportAllPDFReportsClicked) {
      setIsReportsRedirectedToEmailModal(true);
    }
  }, [exportCandidateList?.value?.isAsync]);

  useEffect(() => {
    if (exportCandidateList?.value?.url && exportAllPDFReportsClicked) {
      setIsReportsDownloadLinkReady(true);
    }
  }, [exportCandidateList?.value?.url]);

  return (
    <Fragment>
      <div
        className={`flex-1 overflow-hidden flex flex-col mr-auto ml-auto w-full`}
      >
        <HiringFlowModules tracking={true} />

        <FiltersSideBar
          sidebar={{
            children: <CandidateListFilters />,
            dropdownOptions: {
              hide: !(
                checkPermissions("GET_POSITIONS") &&
                checkPermissions("GET_APPLICANTS") &&
                isObjEmpty(candidateList.error)
              ),
              options: dropdownOptions,
            },
          }}
          title={{
            text: renderModuleName(module?.moduleName),
            children: (
              <div
                className={
                  "w-[40px] h-[40px] p-[15px] rounded-[11px] bg-main-color-light text-main-color flex justify-center items-center font-[600] text-[22px] "
                }
              >
                {Number(key.stepId)}
              </div>
            ),
          }}
          searchBar={{
            placeholder: "Search Candidates",
          }}
        >
          <div className={`flex-1 overflow-hidden flex flex-col bg-white pt-1`}>
            <Outlet />
          </div>
        </FiltersSideBar>
      </div>
      {downloadingCVSModal && (
        <CreatePortal>
          <Modal close={() => null} preventAutoScroll={true}>
            <div className="flex flex-col items-center gap-4 w-[305px]">
              <div className="p-3 rounded-full bg-gray">
                <CsvIco className="w-[30px] h-auto" />
              </div>
              <h2 className="text-center text-[22px] font-medium">
                This might take a while
              </h2>
              <p className="text-center text-normal font-light">
                We're preparing your CSV file.
                <br /> Please be patient.
              </p>
              <Spinner />
            </div>
          </Modal>
        </CreatePortal>
      )}
      {importAppModal && (
        <CreatePortal>
          <Modal
            close={() => setImportAppModal(false)}
            preventAutoScroll={true}
          >
            <ImportApplicantsForm close={() => setImportAppModal(false)} />
          </Modal>
        </CreatePortal>
      )}
      {exportCandidateList?.loading && downloadingReportsModal && (
        <CreatePortal>
          <Modal close={() => null} preventAutoScroll={true}>
            <div className="flex flex-col items-center gap-4 w-[305px]">
              <div className="p-3 rounded-full bg-ultra-light-gray">
                <PdfIco className="w-[30px] h-auto" />
              </div>
              <h2 className="text-center text-[22px] font-medium">
                This might take a while
              </h2>
              <p className="text-center text-normal font-light">
                We're preparing your reports.
                <br /> Please be patient.
              </p>
              <Spinner />
            </div>
          </Modal>
        </CreatePortal>
      )}
      {isReportsDownloadLinkReady && (
        <CreatePortal>
          <Modal close={() => null} preventAutoScroll={true}>
            <div className="flex flex-col items-center gap-4 w-[400px]">
              <div className="p-3 rounded-full bg-ultra-light-gray">
                <FaCheck className="text-main-color text-[22px]" />
              </div>
              <h2 className="text-center text-[22px] font-medium">
                Your report is ready for download
              </h2>
              <p className="text-center text-normal font-light">
                If the download did not start automatically, please click the
                button below
              </p>
              <div className="flex items-center flex-col-reverse gap-3 w-full">
                <Button
                  type="Secondary"
                  text="Close"
                  className="w-1/2 mt-2 rounded-xl"
                  onClick={() => {
                    dispatch(clearExportCandidateList());
                    setExportAllPDFReportsClicked(false);
                    setIsReportsDownloadLinkReady(false);
                  }}
                />
                <a
                  href={exportCandidateList?.value?.url}
                  className="w-1/2 mt-2 rounded-xl bg-main-color py-1.5 text-white text-center"
                  onClick={() => {
                    dispatch(clearExportCandidateList());
                    setExportAllPDFReportsClicked(false);
                    setIsReportsDownloadLinkReady(false);
                  }}
                >
                  Download
                </a>
              </div>
            </div>
          </Modal>
        </CreatePortal>
      )}
      {isReportsRedirectedToEmailModal && (
        <CreatePortal>
          <Modal close={() => null} preventAutoScroll={true}>
            <div className="flex flex-col items-center gap-4 w-[400px]">
              <div className="p-3 rounded-full bg-ultra-light-gray">
                <DocIco className="[&_path]:fill-main-color w-[30px] h-auto" />
              </div>
              <h2 className="text-center text-[22px] font-medium">
                Your Data is on its Way!
              </h2>
              <p className="text-center text-normal font-light">
                There's a lot of data here, so we'll have to package it in the
                background and sent it to you via email{" "}
                <span className="font-medium">{`(${exportCandidateList?.value?.email})`}</span>
                . It may take some time, so grab a cup of hot, delicious coffee
              </p>
              <Button
                type="Primary"
                text="OK"
                className="w-1/2 mt-2 rounded-xl"
                onClick={() => {
                  dispatch(clearExportCandidateList());
                  setExportAllPDFReportsClicked(false);
                  setIsReportsRedirectedToEmailModal(false);
                }}
              />
            </div>
          </Modal>
        </CreatePortal>
      )}
    </Fragment>
  );
};

export default PositionTrackingWrapper;
