import AdditionalFiltersNew from "../../../../components/FiltersSideBar/components/AdditionalFiltersNew";
import { FiltersSideBarType } from "../../../../components/FiltersSideBar/filters-side-bar.type";
import { useMemo } from "react";
import CandidateListStateFilters from "./components/CandidateListStateFilters";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../redux/reduxTypes";
import { useLocation } from "react-router";
import { setGlobalFilters } from "../../../../../redux/globalFilters";
import { useAuthorization } from "../../../../../common/hooks/useAuthorization";
import CandidateListAdvancedFilters from "./components/CandidateListAdvancedFilters";

const CandidateListFilters = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { checkPermissions, checkRoles } = useAuthorization();

  const globalFilters: any = useAppSelector(
    (state) => state.globalFilters[location.pathname],
  );
  const candidateList = useAppSelector(
    (store) => store?.position.positionCandidateList,
  );

  const statusFilters: FiltersSideBarType[] = useMemo(() => {
    return [
      {
        children: <CandidateListStateFilters />,
        type: "custom",
      },
    ];
  }, []);

  const hasStateFilters = useMemo(() => {
    return globalFilters?.filters?.state;
  }, [globalFilters?.filters]);
  const hasAdvancedFilters = useMemo(() => {
    return globalFilters?.filters?.score;
  }, [globalFilters?.filters]);

  const handleClearStatusFilters = () => {
    const { state, ...newFilters } = globalFilters?.filters;
    dispatch(
      setGlobalFilters({
        location: location.pathname,
        filters: newFilters,
        page: 1,
      }),
    );
  };

  const handleClearAdvancedFilters = () => {
    const { score, ...newFilters } = globalFilters?.filters;
    dispatch(
      setGlobalFilters({
        location: location.pathname,
        filters: newFilters,
        page: 1,
      }),
    );
  };

  const handleClearDateFilters = () => {
    const { to_date, from_date, ...newFilters } = globalFilters?.filters;
    dispatch(
      setGlobalFilters({
        location: location.pathname,
        filters: newFilters,
        page: 1,
      }),
    );
  };

  const checkFollowingPermissions = () => {
    return checkRoles("company") && checkPermissions("FOLLOW_APPLICATION");
  };

  const followingFilters: FiltersSideBarType[] = useMemo(() => {
    return [
      {
        type: "following",
        totalFollowedByMe: candidateList?.value?.totalFollowedByMe || 0,
        totalNotFollowed: candidateList?.value?.totalNotFollowed || 0,
        filter_id: "followerIds",
      },
    ];
  }, [candidateList?.value]);

  const advancedFilters: FiltersSideBarType[] = useMemo(() => {
    return [
      {
        children: <CandidateListAdvancedFilters />,
        type: "custom",
      },
    ];
  }, []);

  const dateFilters: FiltersSideBarType[] = useMemo(() => {
    return [
      {
        type: "date",
        filter_id: "from_date",
        placeholder: "From",
      },
      {
        type: "date",
        filter_id: "to_date",
        placeholder: "To",
      },
    ];
  }, []);

  return (
    <AdditionalFiltersNew
      additionalFilters={[
        {
          additionalFilters: statusFilters,
          header: "Status",
          hasFilters: {
            status: hasStateFilters,
            clearFilters: () => handleClearStatusFilters(),
          },
          loading: candidateList?.loading,
        },
        checkFollowingPermissions()
          ? {
              header: "Following",
              additionalFilters: followingFilters,
              loading: candidateList?.loading,
            }
          : {},
        {
          additionalFilters: advancedFilters,
          header: "Advanced",
          hasFilters: {
            status: hasAdvancedFilters,
            clearFilters: () => handleClearAdvancedFilters(),
          },
        },
        {
          header: "Date",
          additionalFilters: dateFilters,
          className: "flex !flex-col !gap-2",
          hasFilters: {
            status: !!(
              globalFilters?.filters["from_date"] ||
              globalFilters?.filters["to_data"]
            ),
            clearFilters: () => handleClearDateFilters(),
          },
        },
      ]}
    />
  );
};

export default CandidateListFilters;
