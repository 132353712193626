import { useEffect } from "react";

const useHorizontalScroll = (containerSelector: string) => {
  // containerSelector MUST be unique if 2 or more scrollable divs are found in the same page
  // MAKE SURE TO SEARCH FOR EXISTING CLASSNAME WHEN NAMING THE SCROLLABLE DIV CLASS
  const handleWheel = (event: WheelEvent) => {
    event.preventDefault();
    const delta = -Math.max(-1, Math.min(1, event.deltaY || -event.detail));
    const scrollAmount = 50;
    const container = document.querySelector(
      containerSelector
    ) as HTMLElement | null;
    if (container) {
      container.scrollLeft -= delta * scrollAmount;
    }
  };

  useEffect(() => {
    const container = document.querySelector(
      containerSelector
    ) as HTMLElement | null;
    if (container) {
      //Add listeners to the selected div if found in the code
      container.addEventListener("wheel", handleWheel, { passive: false });
      container.addEventListener("mouseenter", () =>
        container.addEventListener("wheel", handleWheel, { passive: false })
      );
      container.addEventListener("mouseleave", () =>
        container.removeEventListener("wheel", handleWheel)
      );

      // Cleanup function to remove the event listeners when the component unmounts
      return () => {
        container.removeEventListener("wheel", handleWheel);
        container.removeEventListener("mouseenter", () =>
          container.addEventListener("wheel", handleWheel, { passive: false })
        );
        container.removeEventListener("mouseleave", () =>
          container.removeEventListener("wheel", handleWheel)
        );
      };
    }
  }, [containerSelector]); // Re-run the effect only if the selector changes
};

export default useHorizontalScroll;
