// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";
import {
  createTest,
  getTestTemplates,
  getTests,
  getTestsForModule,
} from "./testLibrary.actions";
import {
  TestTemplatesListType,
  type TestLibraryListType,
  type TestLibraryWizardType,
} from "../testLibrary.types";
import { formatTestState } from "../../../../common/helpers/functions.helpers";
interface TestLibraryType {
  testLibraryList: TestLibraryListType;
  testTemplatesList: TestTemplatesListType;
  testLibraryForm: TestLibraryWizardType;
}

const initialState: TestLibraryType = {
  testLibraryList: {
    loading: true,
    values: null,
    error: null,
  },
  testTemplatesList: {
    values: null,
    loading: false,
    error: null,
  },
  testLibraryForm: {
    navigate: null,
    processing: false,
    template: null,
    error: null,
  },
};

export const testLibrary = createSlice({
  name: "testLibrary",
  initialState,
  reducers: {
    clearTestLibraryList: (state) => {
      state.testLibraryList = initialState.testLibraryList;
    },
    clearTestTemplates: (state) => {
      state.testTemplatesList = initialState.testTemplatesList;
    },
    clearTestLibraryForm: (state) => {
      state.testLibraryForm = initialState.testLibraryForm;
    },
    selectTestWizardTemplate: (state, action) => {
      state.testLibraryForm.template = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTests.pending, (state) => {
        state.testLibraryList.loading = true;
      })
      .addCase(getTests.fulfilled, (state, { payload }) => {
        state.testLibraryList.values = payload;
        state.testLibraryList.loading = false;
      })
      .addCase(getTests.rejected, (state, { payload }: any) => {
        state.testLibraryList.error =
          payload?.code === "ERR_CANCELED" ? null : payload;
        state.testLibraryList.loading = payload?.code === "ERR_CANCELED";
      })
      .addCase(getTestsForModule.pending, (state) => {
        state.testLibraryList.loading = true;
      })
      .addCase(getTestsForModule.fulfilled, (state, { payload }) => {
        state.testLibraryList.values = payload;
        state.testLibraryList.loading = false;
      })
      .addCase(getTestsForModule.rejected, (state, { payload }) => {
        state.testLibraryList.error = payload;
        state.testLibraryList.loading = false;
      })
      .addCase(createTest.pending, (state) => {
        state.testLibraryForm.processing = true;
      })
      .addCase(createTest.fulfilled, (state, action) => {
        state.testLibraryForm.template = null;
        // @ts-ignore
        state.testLibraryForm.navigate = `${formatTestState(action.payload.state?.toLowerCase())}/${action.payload._id}`;
        state.testLibraryForm.processing = false;
      })
      .addCase(createTest.rejected, (state, { payload }) => {
        state.testLibraryForm.error = payload;
        state.testLibraryForm.processing = false;
      })
      .addCase(getTestTemplates.pending, (state) => {
        state.testTemplatesList.loading = true;
      })
      .addCase(getTestTemplates.fulfilled, (state, { payload }) => {
        state.testTemplatesList.values = payload;
        state.testTemplatesList.loading = false;
      })
      .addCase(getTestTemplates.rejected, (state, action) => {
        state.testTemplatesList.error = action.payload;
        state.testTemplatesList.loading = false;
      });
  },
});

export const {
  clearTestLibraryList,
  selectTestWizardTemplate,
  clearTestLibraryForm,
  clearTestTemplates,
} = testLibrary.actions;

export default testLibrary.reducer;
