import Button from "views/components/Button/Button";
import { useState } from "react";
import UploadDropzone from "views/components/UploadInput/UploadDropzone";
import { ReactComponent as CsvIco } from "assets/icons/csv-ico.svg";
import { useLocation, useParams } from "react-router";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../redux/reduxTypes";
import {
  downloadPositionTemplate,
  importPositionTemplate,
} from "../../reducer/position.actions";
import Spinner from "../../../../components/spinners/Spinner";

type inputType = {
  error?: boolean;
};

const initialInput: inputType = {
  error: false,
};

const ImportApplicantsForm = ({ close }: { close?: () => void }) => {
  const [input, setInput] = useState<inputType>(initialInput);
  const [file, setFile] = useState<File | null>(null);
  const key = useParams();
  const dispatch = useAppDispatch();
  const downloadLoading = useAppSelector(
    (state) => state.position.downloadTemplate.loading,
  );
  const location = useLocation();
  const importLoading = useAppSelector(
    (state) => state.position.importTemplate.loading,
  );
  const position = useAppSelector(
    (state) => state.position.positionOverview?.value,
  );
  const globalFilters = useAppSelector(
    (state) => state.globalFilters[location.pathname],
  );

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (file && key.id && key.stepId) {
      dispatch(
        importPositionTemplate({
          paramsArray: globalFilters,
          positionId: key.id,
          file,
          stepId: key.stepId,
        }),
      ).then((res) => {
        if (close) {
          close();
        }
      });
    } else {
      setInput({ ...input, error: true });
    }
  }
  const handleUploadFile = (file: File) => {
    setFile(file);
  };

  const handleDownloadTemplate = () => {
    if (!key.id) return;
    dispatch(downloadPositionTemplate({ positionId: key.id })).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.payload as any]));

      const link = document.createElement("a");
      link.href = url;
      link.download = position?.name
        ? `${position.name.toLowerCase().replace(/ /g, "-")}-template.csv`
        : "template-position.csv";

      document.body.appendChild(link);

      link.click();
      link.remove();
    });
  };

  return (
    <>
      <div className="w-[500px] flex flex-col items-center justify-center zmd:max-w-[420px] zmd:w-full zmd:mx-auto">
        <form
          onSubmit={handleSubmit}
          className="mx-auto rounded-lg flex flex-col items-start zmd:items-center gap-5 zmd:w-full"
        >
          <h1 className="text-[26px] text-black zmd:text-center">
            Import Candidates
          </h1>
          <div
            className={"flex flex-col gap-3 mt-5"}
            onClick={() => handleDownloadTemplate()}
          >
            <p className="text-sm text-gray -mt-6 font-light zmd:text-center zsm:-mb-4">
              Download the following CSV template and fill it out with your
              candidates:
            </p>
            <div
              className={`hover:bg-[#d3fbcf] cursor-pointer rounded-[10px] p-[10px] bg-[#E0FFDD] 
                            w-full text-[#48A874] text-base flex justify-start items-center gap-2 ${downloadLoading ? "select-none opacity-80" : ""}`}
            >
              <CsvIco className={"[&_path]:fill-[#48A874] w-[25px]"} />
              Download CSV Template
              {downloadLoading ? (
                <Spinner classIcon={"w-[15px] h-[15px]"} />
              ) : null}
            </div>
            <hr className=" mt-4 border-1 border-[#D1D1D1] w-full" />
          </div>
          <div className="flex flex-col gap-6 w-full">
            <div className="flex flex-col items-start">
              <p className="text-dark-gray text-sm font-light mb-3">
                Once you have the template filled out, drop it here:
              </p>
              <UploadDropzone
                accept={["csv"]}
                onChange={handleUploadFile}
                onRemove={() => setFile(null)}
                invalid={!file && input.error}
                placeholder={"Drop your CSV file here or "}
              />
            </div>
          </div>
          <div className="flex flex-row-reverse gap-3 zsm:flex-col w-full">
            <Button
              type="Primary"
              text="Confirm"
              state={importLoading ? "Loading" : ""}
              className="w-full mt-4 rounded-xl py-2 zsm:mt-2"
              disabled={!file || importLoading}
              isSubmit={true}
            />
            <Button
              type="Secondary"
              text="Cancel"
              className="w-full mt-4 rounded-xl py-2 zsm:mt-2"
              onClick={() => (close ? close() : null)}
            />
          </div>
        </form>
      </div>
    </>
  );
};

export default ImportApplicantsForm;
