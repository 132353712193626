import { createAsyncThunk, nanoid } from "@reduxjs/toolkit";
// ** Axios Imports
import { http } from "common/api/http";

export const downloadPdfZipReports = createAsyncThunk(
  "exception/downloadPdfZipReports",
  async ({ path }: { path: string }, { rejectWithValue }) => {
    try {
      const response = await http.get(`system/pdf-report-zipSS/${path}`);

      if (response.data.url) {
        const nanoId = nanoid();

        const link = document.createElement("a");
        link.href = response.data.url;
        link.download = `${nanoId}-reports.zip`;

        document.body.appendChild(link);

        link.click();
        link.remove();
      }

      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "ACTION",
        style: "TOAST",
      });
    }
  },
);
