import {
  FunctionComponent,
  RefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import { NavLink } from "react-router-dom";
import Avatar from "../Avatar/Avatar";
import Spinner from "../spinners/Spinner";
import { useAppDispatch, useAppSelector } from "redux/reduxTypes";
import { useLocation } from "react-router";
import { setScrollDirection } from "redux/global";
import { useOutsideClickHandler } from "common/hooks/useOutsideClickHandler";
import { renderFileUrl } from "common/helpers/functions.helpers";
import { useAuthorization } from "common/hooks/useAuthorization";
import { ReactComponent as ArrowDown } from "assets/icons/arrow-green-down.svg";
import noImageImg from "assets/icons/no-image.svg";
import { ReactComponent as SettingsIco } from "assets/icons/cog-ico-3.svg";
import { companyItemType } from "../../features/companies/companies.type";
import { ImagePreview } from "../FilePreview/ImagePreview";
import ProfileDropdown from "../ProfileDropdown/ProfileDropdown";
import zenhire from "assets/icons/zenhire-ico.png";

const navLinks = [
  {
    label: "Dashboard",
    to: "",
  },
  {
    label: "Applicants",
    to: "/applicants",
    permissions: "GET_APPLICANTS",
  },
  {
    label: "Positions",
    to: "/positions",
    permissions: "GET_POSITIONS",
  },
  {
    label: "Test Library",
    to: "/test-library/published",
    permissions: "GET_TESTS",
  },
  {
    label: "Our Team",
    to: "/users",
    permissions: "GET_USERS",
  },
];

type propTypes = {
  companyData: companyItemType | null;
  idCompany: string;
};
const ClientNavbar: FunctionComponent<propTypes> = (props) => {
  const scrollDirection = useAppSelector(
    (state) => state.global.scrollDirection
  );
  const dispatch = useAppDispatch();
  const userData = useAppSelector((state) => state.authentication.userData);
  const { companyData, idCompany } = props;
  const [isProfileDropdownOpen, setIsProfileDropdownOpen] = useState(false);
  const ref: RefObject<HTMLDivElement> = useRef(null);
  const location = useLocation();
  const { checkPermissions } = useAuthorization();
  const closeDropdownHandler = () => {
    setIsProfileDropdownOpen(false);
  };

  function closeMenu() {
    setTimeout(() => {
      closeDropdownHandler();
    }, 50);
  }

  const handleClickOutside = (event?: any) => {
    closeMenu();
  };

  useOutsideClickHandler(ref, handleClickOutside);

  const displayStyle = () => {
    if (scrollDirection === "DOWN") {
      closeMenu();
      return "-mt-[46px] transition-all";
    } else {
      if (scrollDirection === "UP") {
        return "mt-0 transition-all";
      }
      setTimeout(() => {
        return "relative z-50 block";
      }, 102);
    }

    return "relative z-50 block bg-black";
  };

  useEffect(() => {
    displayStyle();
  }, [scrollDirection]);

  useEffect(() => {
    if (scrollDirection === "UP") {
      setTimeout(() => {
        dispatch(setScrollDirection(null));
      }, 100);
    }
  }, [scrollDirection]);

  return (
    <>
      {scrollDirection === "DOWN" ? (
        <>
          <div
            className="fixed w-full h-[17px] bg-transparent z-[999] cursor-pointer"
            onMouseEnter={() => {
              scrollDirection === "DOWN" && dispatch(setScrollDirection("UP"));
            }}
          >
            <div className="text-green fixed z-[888] top-2 fade-in-top w-full flex items-center justify-center">
              <ArrowDown className={"w-[12px] [&_path]:fill-main-color"} />
            </div>
          </div>
        </>
      ) : null}
      <div
        ref={ref}
        style={{
          background: companyData?.enableHeaderColor
            ? companyData?.headerColor
            : companyData?.color
              ? companyData?.color
              : "black",
        }}
        className={`${displayStyle()} bg-black z-[40] border-b border-z-grey-200`}
      >
        <div className="flex items-center m-auto h-[46px] px-[20px]">
          <NavLink
            to={`/${idCompany}`}
            className="flex items-center cursor-pointer gap-2 mr-auto h-full"
          >
            <ImagePreview
              src={
                companyData?.headerLogo && companyData?.enableHeaderLogo
                  ? renderFileUrl(companyData.headerLogo)
                  : !companyData?.headerLogo && companyData?.enableHeaderLogo
                    ? noImageImg
                    : companyData?.logo
                      ? renderFileUrl(companyData.logo)
                      : zenhire
              }
              className="max-h-[30px]"
              alt={"company-ico"}
              noImageClass={
                "!bg-transparent !w-10 rounded-[50%] !h-10  border-none"
              }
            />
          </NavLink>

          <nav className="text-[13px] font-[500]">
            {navLinks
              .filter((link) =>
                (link.permissions && checkPermissions(link.permissions)) ||
                !link.permissions
                  ? link
                  : null
              )
              .map((link, index) => (
                <NavLink
                  key={index}
                  to={`/${idCompany}${link.to}`}
                  className={({ isActive }) =>
                    `py-[10px] px-3.5 client-nav-link ${
                      isActive
                        ? "text-white border-b-[2px] active-client-nav-link"
                        : ""
                    }`
                  }
                  end={!link.to}
                  style={{
                    color: companyData?.linkColor
                      ? companyData?.linkColor
                      : "#FFFFFF80",
                  }}
                >
                  {link.label}
                </NavLink>
              ))}
          </nav>

          <div className="relative flex items-center gap-5 ml-7">
            {checkPermissions("UPDATE_COMPANY") ? (
              <NavLink
                to={`/${idCompany}/settings`}
                className={({ isActive }) =>
                  `py-[9px] relative ${
                    isActive ? "active-client-settings-ico" : ""
                  }`
                }
              >
                <SettingsIco
                  className={`client-settings-ico [&_path]:fill-main-link-color`}
                />
                {location?.pathname.startsWith(`/${idCompany}/settings`) ? (
                  <div
                    className={
                      "border-b-[2px] border-main-link-color absolute bottom-0 left-0 w-full"
                    }
                  ></div>
                ) : null}
              </NavLink>
            ) : null}
            {userData.loading || userData.processing || !userData.value ? (
              <Spinner classIcon={"w-[30px] h-[30px]"} />
            ) : (
              <>
                <div
                  className="cursor-pointer w-[30px] h-[30px]"
                  onClick={() => {
                    setIsProfileDropdownOpen((prevState) => !prevState);
                  }}
                >
                  <Avatar
                    content={`${userData.value.name} ${userData.value.surname}`}
                    avatar={userData.value.avatar}
                    size={30}
                    containerClass={userData.value.avatar ? "" : "border"}
                  />
                </div>

                {isProfileDropdownOpen && (
                  <div
                    className="absolute top-12 right-0 z-50"
                    onMouseLeave={closeDropdownHandler}
                  >
                    <ProfileDropdown
                      img={userData.value.avatar}
                      closeDropdown={closeDropdownHandler}
                      contentName={`${userData.value.name} ${userData.value.surname}`}
                      role={userData.value.role.description}
                      fullName={`${userData.value.name} ${userData.value.middleName} ${userData.value.surname}`}
                      settingsLink={`/${idCompany}/profile-settings/general`}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ClientNavbar;
