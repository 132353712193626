// ** Redux Imports
// import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

import { createAsyncThunk } from "@reduxjs/toolkit";

// ** Axios Imports
import { http } from "common/api/http";
import { loginTypes, userDataType } from "../auth.types";
import { setToast } from "redux/alert";
import { fetchMe } from "./auth.services";
import { tokenTypes } from "common/types/api.types";
import { removeKey, storeKey } from "common/api/token";
import { publicHttp } from "common/api/public-http";
import { changeAuthPassword } from "./authentication";

export const loginUser = createAsyncThunk<userDataType, loginTypes, {}>(
  "authentication/login",
  async (
    { email, password, reCaptchaToken },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response: { data: tokenTypes } = await publicHttp.post(
        "/auth/login",
        {
          email,
          password,
        },
        {
          headers: {
            "g-recaptcha-token": `${reCaptchaToken}`,
          },
        }
      );
      if (response.data?.access_token && response.data?.refresh_token)
        storeKey({
          access_token: response.data.access_token,
          refresh_token: response.data.refresh_token,
        });

      const response1 = await fetchMe();

      if (response?.data?.isPasswordChangedAfterInitialLogin) {
        dispatch(
          setToast({
            message: "Welcome back!",
            type: "success",
          })
        );
      }

      return response1.data as userDataType;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "FORM",
        style: "INFO_BOX",
      });
    }
  }
);

export const forgotPassword = createAsyncThunk(
  "authentication/forgotPassword",
  async (
    {
      email,
      password,
      verificationToken,
    }: { email: string; password?: string; verificationToken?: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await publicHttp.post(
        password && verificationToken
          ? `/auth/reset-password?email=${email}&verificationToken=${verificationToken}`
          : "/auth/forgot-password",
        password && verificationToken
          ? {
              newPassword: password,
            }
          : {
              email,
            }
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "FORM",
        style: "INFO_BOX",
      });
    }
  }
);

export const getMe = createAsyncThunk(
  "authentication/me",
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetchMe();
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "ACTION",
        style: "TOAST",
      });
    }
  }
);

export const updateMe = createAsyncThunk(
  "authentication/updateMe",
  async (
    {
      name,
      surname,
      middleName,
      avatar,
    }: {
      name?: string;
      surname?: string;
      middleName?: string;
      avatar?: string;
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await http.patch("/users/admins", {
        name,
        surname,
        middleName,
        avatar,
      });
      dispatch(
        setToast({
          message: "Successfully updated profile info.",
          type: "success",
        })
      );
      return response.data as any;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "ACTION",
        style: "TOAST",
      });
    }
  }
);

export const handleLogout = createAsyncThunk(
  "authentication/logout",
  async (_, { rejectWithValue }) => {
    try {
      const response = await http.post("/auth/logout");
      removeKey("zen_access_token");
      removeKey("zen_refresh_token");
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "ACTION",
        style: "TOAST",
      });
    }
  }
);

export const resetPassword = createAsyncThunk(
  "authentication/resetPassword",
  async (
    {
      oldPassword,
      newPassword,
      firstChange,
    }: {
      oldPassword: string;
      newPassword: string;
      firstChange?: boolean;
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await http.patch("/users/admins/password", {
        oldPassword: oldPassword,
        newPassword,
      });

      if (firstChange) {
        dispatch(changeAuthPassword());
        dispatch(
          setToast({
            message: "Welcome!",
            type: "success",
          })
        );
      } else {
        dispatch(
          setToast({
            message: "You have successfully reset your password.",
            type: "success",
          })
        );
      }

      return response.data as any;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "FORM",
        style: "INFO_BOX",
      });
    }
  }
);
