import { useMemo } from "react";
import _ from "lodash";

const useCheckEqualObjects = (initialObject: Object, object: Object) => {
  return useMemo(() => {
    return !_.isEqual(initialObject, object);
  }, [object, initialObject]);
};

export default useCheckEqualObjects;
