// ** React Imports
import { Suspense } from "react";

import { type routerWrapperType } from "./types";
import LoadingPageCounter from "../../views/components/LoadingPageCounter/LoadingPageCounter";

const ShieldRoute = ({ children, route }: routerWrapperType) => {
  return <Suspense fallback={<LoadingPageCounter />}>{children}</Suspense>;
};

export default ShieldRoute;
