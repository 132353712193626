import { type FC, lazy, ReactNode } from "react";
import { lazyRetry } from "common/utility/Utils";
import { type routeType } from "router/routes/types";

const CompanyOverview: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () =>
        await import("../../companyDashboard/pages/CompanyOverviewPage")
    )
);
const CompanySuspended: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () => await import("../../CompanySuspended/CompanySuspended")
    )
);

const companyDashboardRoutes: routeType[] = [
  {
    path: "/",
    element: <CompanyOverview />,
    meta: {
      title: "Dashboard",
      type: "page",
      layout: "app",
      private: true,
      company: true,
    },
  },
  {
    path: "/suspended",
    element: <CompanySuspended />,
    meta: {
      layout: "blank",
      type: "page",
      title: "Company Suspended",
      private: true,
      company: true,
    },
  },
];
export default companyDashboardRoutes;
