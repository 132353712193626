import { type FC, lazy } from "react";
import { lazyRetry } from "common/utility/Utils";
import { type routeType } from "router/routes/types";

const Dashboard: FC | any = lazy(
  async () => await lazyRetry(async () => await import("../Dashboard"))
);

const dashboardRoutes: routeType[] = [
  {
    path: "/",
    element: <Dashboard />,
    meta: {
      layout: "app",
      title: "Dashboard",
      type: "page",
      private: true,
      roles: ["zenhire"],
    },
  },
];

export default dashboardRoutes;
