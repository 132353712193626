// ** Redux Imports
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface FiltersState {
  [key: string]: {
    filters: {};
    page: 1;
    search: "";
    sort: "";
    limit: 10;
  };
}

const initialState: FiltersState = {};

export const globalFiltersSlice = createSlice({
  name: "globalFilters",
  initialState,

  reducers: {
    setGlobalFilters: (state, action) => {
      const { location, filters, page, search, sort, limit } = action.payload;

      if (!state[location]) {
        state[location] = {
          filters: {},
          page: 1,
          search: "",
          sort: "",
          limit: 10,
        };
      }
      if (filters !== undefined) {
        state[location].filters = filters;
      }

      if (page !== undefined) {
        state[location].page = page;
      }

      if (search !== undefined) {
        state[location].search = search;
      }

      if (sort !== undefined) {
        state[location].sort = sort;
      }

      if (limit !== undefined) {
        state[location].limit = limit;
      }
    },
    clearGlobalFilters: (
      state,
      action: PayloadAction<{ location: string }>
    ) => {
      const { location } = action.payload;
      delete state[location];
    },
  },
  extraReducers: (builder) => {},
});
export const { setGlobalFilters, clearGlobalFilters } =
  globalFiltersSlice.actions;
export default globalFiltersSlice.reducer;
