import { createSlice } from "@reduxjs/toolkit";
import { testOverviewType } from "views/features/testSingle/testSingle.types";
import {
  getActiveModule,
  getApplication,
  getApplicationFeedback,
  getApplicationSurveyTemplate,
  getPositionSimulationInfo,
  getTestApplicant,
  getTestSimulationInfo,
  getTestSimulationPublicResults,
  handleSubmitSurvey,
  submitApplication,
  submitPositionApplication,
  updatePositionSimulationInfo,
  verifyApplication,
} from "./application.actions";

import {
  positionFeedbackType,
  positionSimulationType,
  testCandidateType,
} from "../application.types";
import { errorType } from "common/types/reducer.types";

interface initialStateTypes {
  testSimulationInfo: {
    value: testOverviewType | null;
    loading: boolean;
    refreshing: boolean;
    processing: boolean;
    isDemo: boolean;
    error: errorType | any;
  };
  positionSimulationInfo: {
    value: positionSimulationType | null;
    loading: boolean;
    refreshing: boolean;
    processing: boolean;
    isDemo: boolean;
    error: errorType | any;
  };
  application: {
    value: any;
    processing: boolean;
    loading: boolean;
    error: errorType | any;
  };
  module: {
    value: any;
    processing: boolean;
    loading: boolean;
    error: errorType | any;
  };
  candidate: {
    value: testCandidateType | null;
    loggedIn: boolean;
    loading: boolean;
    applicationId: null | string;
    alreadyApplied: boolean;
    typeSuccess: string;
    processing: boolean;
    error: errorType | any;
  };
  publicTestResults: {
    value: any;
    loading: boolean;
    error: errorType | any;
  };
  applicationFeedback: {
    value: positionFeedbackType | null;
    loading: boolean;
    error: errorType | any;
  };
  applicationSurveyAnswers: {
    value: any;
    loading: boolean;
    error: errorType | any;
  };
  applicationSurvey: {
    loading: boolean;
    submitted: boolean;
    error: errorType | any;
  };
  applicationJDUpdated: boolean;
}

const initialState: initialStateTypes = {
  testSimulationInfo: {
    value: null,
    loading: false,
    refreshing: false,
    processing: false,
    isDemo: false,
    error: null,
  },
  positionSimulationInfo: {
    value: null,
    loading: false,
    refreshing: false,
    processing: false,
    isDemo: false,
    error: null,
  },
  candidate: {
    value: null,
    loggedIn: false,
    loading: false,
    applicationId: null,
    alreadyApplied: false,
    processing: false,
    typeSuccess: "",
    error: null,
  },
  application: {
    value: null,
    processing: false,
    error: null,
    loading: false,
  },
  module: {
    value: null,
    processing: false,
    error: null,
    loading: false,
  },

  publicTestResults: {
    value: null,
    loading: true,
    error: null,
  },
  applicationFeedback: {
    value: null,
    loading: true,
    error: null,
  },
  applicationSurvey: {
    loading: false,
    submitted: false,
    error: null,
  },
  applicationSurveyAnswers: {
    value: null,
    loading: false,
    error: null,
  },
  applicationJDUpdated: false,
};

const application = createSlice({
  name: "application",
  initialState,
  reducers: {
    setIsTestDemo(state, { payload }) {
      state.testSimulationInfo.isDemo = payload;
    },
    setCandidateValue(state, { payload }) {
      state.candidate.value = payload;
    },
    setAssessmentSimulationModule(state, { payload }) {
      state.module.value = payload;
    },
    clearApplicationSession(state) {
      state.application = initialState.application;
      state.candidate = initialState.candidate;
      state.module = initialState.module;
    },
    clearApplicationCandidate(state) {
      state.candidate = initialState.candidate;
    },
    clearTestSimulationApply(state) {
      state.testSimulationInfo = initialState.testSimulationInfo;
    },
    clearPositionSimulationInfo(state) {
      state.positionSimulationInfo = initialState.positionSimulationInfo;
    },
    clearPositionSimulationInfoError(state) {
      state.positionSimulationInfo.error =
        initialState.positionSimulationInfo.error;
    },
    clearPublicTestResults(state) {
      state.publicTestResults = initialState.publicTestResults;
    },
    updateJDDescription(state, { payload }) {
      state.applicationJDUpdated = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTestSimulationInfo.pending, (state) => {
      state.testSimulationInfo.loading = true;
    });
    builder.addCase(getTestSimulationInfo.fulfilled, (state, { payload }) => {
      state.testSimulationInfo.value = payload;
      state.testSimulationInfo.loading = false;
    });
    builder.addCase(getTestSimulationInfo.rejected, (state, { payload }) => {
      state.testSimulationInfo.loading = false;
      state.testSimulationInfo.error = payload;
    });
    builder.addCase(getPositionSimulationInfo.pending, (state) => {
      state.positionSimulationInfo.loading = true;
    });
    builder.addCase(
      getPositionSimulationInfo.fulfilled,
      (state, { payload }) => {
        state.positionSimulationInfo.value = payload;
        state.positionSimulationInfo.loading = false;
      },
    );
    builder.addCase(
      getPositionSimulationInfo.rejected,
      (state, { payload }) => {
        state.positionSimulationInfo.loading = false;
        state.positionSimulationInfo.error = payload;
      },
    );
    builder
      .addCase(updatePositionSimulationInfo.pending, (state) => {
        state.positionSimulationInfo.processing = true;
      })
      .addCase(updatePositionSimulationInfo.fulfilled, (state, { payload }) => {
        state.positionSimulationInfo.value = payload;
        state.positionSimulationInfo.processing = false;
      })
      .addCase(updatePositionSimulationInfo.rejected, (state, { payload }) => {
        state.positionSimulationInfo.error = payload;
        state.positionSimulationInfo.processing = false;
      });
    builder.addCase(submitApplication.pending, (state) => {
      state.candidate.loading = true;
    });
    builder.addCase(submitApplication.fulfilled, (state, { payload }) => {
      state.candidate.processing = false;
      state.candidate.loading = false;
      state.candidate.applicationId = payload?.id || null;
    });
    builder.addCase(submitApplication.rejected, (state, { payload }) => {
      state.candidate.processing = false;
      state.candidate.loading = false;
      state.candidate.error = payload;
    });
    builder.addCase(submitPositionApplication.pending, (state) => {
      state.candidate.loading = true;
      state.candidate.processing = true;
    });
    builder.addCase(
      submitPositionApplication.fulfilled,
      (state, { payload }) => {
        state.candidate.processing = false;
        state.candidate.loading = false;
        state.candidate.applicationId = payload?.applicationId || null;
        state.candidate.typeSuccess = payload?.nextStepEmailTemplate || "";
        state.candidate.alreadyApplied = payload?.alreadyApplied || false;
      },
    );
    builder.addCase(
      submitPositionApplication.rejected,
      (state, { payload }) => {
        state.candidate.processing = false;
        state.candidate.loading = false;
        state.candidate.error = payload;
      },
    );
    builder.addCase(getApplication.pending, (state) => {
      state.application.loading = true;
    });
    builder.addCase(getApplication.fulfilled, (state, { payload }) => {
      state.application.loading = false;
      state.application.value = payload;
    });
    builder.addCase(getApplication.rejected, (state, { payload }) => {
      state.application.loading = false;
      state.application.error = payload;
    });
    builder.addCase(getActiveModule.pending, (state) => {
      state.module.loading = true;
    });
    builder.addCase(getActiveModule.fulfilled, (state, { payload }) => {
      state.module.loading = false;
      state.module.value = payload;
    });
    builder.addCase(getActiveModule.rejected, (state, { payload }) => {
      state.module.loading = false;
      state.module.error = payload;
    });
    builder.addCase(verifyApplication.pending, (state) => {
      state.candidate.processing = true;
    });
    builder.addCase(verifyApplication.fulfilled, (state, { payload }) => {
      state.candidate.processing = false;
      state.application.value = payload?.application;
    });
    builder.addCase(verifyApplication.rejected, (state, { payload }) => {
      state.candidate.processing = false;
      state.candidate.error = payload;
    });
    builder.addCase(getTestApplicant.pending, (state) => {
      state.candidate.loading = true;
    });
    builder.addCase(getTestApplicant.fulfilled, (state, { payload }) => {
      state.candidate.loading = false;
      state.candidate.value = payload;
    });
    builder.addCase(getTestApplicant.rejected, (state, { payload }) => {
      state.candidate.loading = false;
      state.candidate.error = payload;
    });
    builder.addCase(getTestSimulationPublicResults.pending, (state) => {
      state.publicTestResults.loading = true;
    });
    builder.addCase(
      getTestSimulationPublicResults.fulfilled,
      (state, { payload }) => {
        state.publicTestResults.loading = false;
        state.publicTestResults.value = payload;
      },
    );
    builder.addCase(
      getTestSimulationPublicResults.rejected,
      (state, { payload }) => {
        state.publicTestResults.loading = false;
        state.publicTestResults.error = payload;
      },
    );
    builder
      .addCase(getApplicationFeedback.pending, (state) => {
        state.applicationFeedback.loading = true;
      })
      .addCase(getApplicationFeedback.fulfilled, (state, { payload }) => {
        state.applicationFeedback.value = payload;
        state.applicationFeedback.loading = false;
      })
      .addCase(getApplicationFeedback.rejected, (state, { payload }) => {
        state.applicationFeedback.error = payload;
        state.applicationFeedback.loading = false;
      });

    builder
      .addCase(handleSubmitSurvey.pending, (state) => {
        state.applicationSurvey.loading = true;
      })
      .addCase(handleSubmitSurvey.fulfilled, (state) => {
        state.applicationSurvey.loading = false;
        state.applicationSurvey.submitted = true;
      })
      .addCase(handleSubmitSurvey.rejected, (state, { payload }) => {
        state.applicationSurvey.error = payload;
        state.applicationSurvey.loading = false;
        state.applicationSurvey.submitted = false;
      });

    builder
      .addCase(getApplicationSurveyTemplate.pending, (state) => {
        state.applicationSurveyAnswers.loading = true;
      })
      .addCase(getApplicationSurveyTemplate.fulfilled, (state, { payload }) => {
        state.applicationSurveyAnswers.loading = false;
        state.applicationSurveyAnswers.value = payload;
      })
      .addCase(getApplicationSurveyTemplate.rejected, (state, { payload }) => {
        state.applicationSurveyAnswers.error = payload;
        state.applicationSurveyAnswers.loading = false;
      });
  },
});

export const {
  setIsTestDemo,
  setCandidateValue,
  setAssessmentSimulationModule,
  clearTestSimulationApply,
  clearApplicationSession,
  clearApplicationCandidate,
  clearPositionSimulationInfo,
  clearPublicTestResults,
  clearPositionSimulationInfoError,
  updateJDDescription,
} = application.actions;

export default application.reducer;
