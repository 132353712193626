import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { useEffect } from "react";
import { $generateNodesFromDOM } from "@lexical/html";
import { $createParagraphNode, $getRoot, $insertNodes } from "lexical";
import { checkTextEditorContent } from "../../TextEditor";
import { emptyTextEditorState } from "../../sampleText";

const UpdateStatePlugin = ({
  value,
  jobDescription,
}: {
  value: any;
  jobDescription?: boolean;
}) => {
  const [editor] = useLexicalComposerContext();
  const checkIsLexicalState = () => {
    const content = checkTextEditorContent(value || "");

    return content.hasImage || content.hasText;
  };

  useEffect(() => {
    if (editor && value) {
      // setTimeout(() => {
      if (
        (jobDescription &&
          value?.length &&
          checkIsLexicalState() &&
          value !== emptyTextEditorState) ||
        !jobDescription
      ) {
        const editorState = editor.parseEditorState(value);
        editor.setEditorState(editorState);
      }

      if (
        value?.length &&
        !checkIsLexicalState() &&
        value !== emptyTextEditorState
      ) {
        editor.update(
          () => {
            // In the browser you can use the native DOMParser API to parse the HTML string.
            const parser = new DOMParser();
            const dom = parser.parseFromString(value, "text/html");

            // Once you have the DOM instance it's easy to generate LexicalNodes.
            const nodes = $generateNodesFromDOM(editor, dom)
              .map((node) => {
                if (node.getType() === "text") {
                  if (node.getTextContent().trim() === "") {
                    return null;
                  } else {
                    return $createParagraphNode().append(node);
                  }
                }

                if (node.getType() === "linebreak") {
                  return null;
                }

                return node;
              })
              .filter((node) => !!node);

            // Select the root
            $getRoot().select();

            // Insert them at a selection.
            $insertNodes(nodes as any);
          },
          { tag: "skip-scroll-into-view" },
        );
      }
      // }, 50);
    }
  }, [value, editor]);

  return <></>;
};

export default UpdateStatePlugin;
