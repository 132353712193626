import { http } from "common/api/http";

export const fetchTestSingle = async (id: string) => {
  return await http.get(`/tests/${id}/overview`);
};

export const fetchTestSingleContents = async (id: string) => {
  return await http.get(`/tests/${id}/contents`);
};

export const duplicateTest = async (id: string) => {
  return await http.post(`/tests/${id}/duplicate`);
};

export const getTestDemoQuestions = async (testId: string) => {
  return await http.get(`/tests/${testId}/questions?isDemo=true`);
};
