import Select from "react-select";
import { useEffect, useState } from "react";

type propsTypes = {
  data: {
    _id: string;
    value: string;
    label: string;
    isDisabled?: boolean;
  }[];
  selectedData?: {
    _id?: string;
    value: string;
    label: string;
    isDisabled?: boolean;
  }[];
  onChange: (selectedValue: any) => void;
  placeholder?: string;
  disabled?: boolean;
};

const SingleFiltersSelect = (props: propsTypes) => {
  const [options, setOptions] = useState(props.data);
  useEffect(() => {
    setOptions(props.data);
  }, [props.data]);

  const handleSelect = (value: any) => {
    props.onChange(value);
  };
  return (
    <div>
      <Select
        options={options}
        value={props.selectedData ? props.selectedData : null}
        onChange={(value: any) => handleSelect(value)}
        placeholder={`${props.placeholder}` || "Select"}
        menuPosition={"fixed"}
        isClearable={false}
        isDisabled={props?.disabled}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            display: "flex",
            borderRadius: 10,
            borderColor: "#BBBBBB",
            "&:hover": "transparent",
            boxShadow: "none",
            height: "35px",
            "::-webkit-scrollbar": {
              width: "1px",
              height: "0px",
            },
            "::-webkit-scrollbar-track": {
              background: "#f1f1f1",
            },
            "::-webkit-scrollbar-thumb": {
              background: "#aaa",
            },
            "::-webkit-scrollbar-thumb:hover": {
              background: "#aaa",
            },
          }),
          container: (baseStyles) => ({
            ...baseStyles,
            borderColor: "#1A1A1A",
          }),
          valueContainer: (baseStyles) => ({
            ...baseStyles,
            order: 2,
            paddingLeft: "0px",
          }),
          placeholder: (baseStyles) => ({
            ...baseStyles,
            color: "#838383",
            fontSize: "13px",
            marginTop: "-1px",
          }),
          indicatorSeparator: (baseStyles) => ({
            ...baseStyles,
            display: "none",
          }),
          menu: (baseStyles) => ({
            ...baseStyles,
            borderRadius: 10,
            padding: 5,
            backgroundColor: "#1A1A1A",
          }),
          option: (baseStyles, state) => ({
            ...baseStyles,
            borderRadius: 10,
            cursor: state.isDisabled ? "unset" : "pointer",
            backgroundColor: state.isFocused
              ? "#3B3B3B"
              : state.isDisabled
                ? "#868686"
                : "#1A1A1A",
            color: "white",
          }),
          menuList: (base) => ({
            ...base,

            "::-webkit-scrollbar": {
              width: "1px",
              height: "0px",
            },
            "::-webkit-scrollbar-track": {
              background: "#f1f1f1",
            },
            "::-webkit-scrollbar-thumb": {
              background: "#aaa",
            },
            "::-webkit-scrollbar-thumb:hover": {
              background: "#aaa",
            },
          }),
        }}
      />
    </div>
  );
};

export default SingleFiltersSelect;
