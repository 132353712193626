// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";
import { downloadPdfZipReports } from "./exception.actions";
import { errorType } from "common/types/reducer.types";

type reducerType = {
  downloadFile: {
    value: null;
    loading: boolean;
    error: errorType | any;
  };
};

const initialState: reducerType = {
  downloadFile: {
    value: null,
    loading: false,
    error: null,
  },
};

export const exception = createSlice({
  name: "exception",
  initialState,
  reducers: {
    clearDownloadFile(state) {
      state.downloadFile = initialState.downloadFile;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(downloadPdfZipReports.pending, (state) => {
        state.downloadFile.loading = true;
      })
      .addCase(downloadPdfZipReports.fulfilled, (state, { payload }) => {
        state.downloadFile.value = payload;
        state.downloadFile.loading = false;
        state.downloadFile.error = false;
      })
      .addCase(downloadPdfZipReports.rejected, (state, { payload }) => {
        state.downloadFile.error = payload;
        state.downloadFile.loading = false;
      });
  },
});

export const { clearDownloadFile } = exception.actions;

export default exception.reducer;
