// ** React Imports
import { Suspense } from "react";
import { Navigate } from "react-router";

// ** Utils
import { type routerWrapperType } from "./types";
import { useAppSelector } from "redux/reduxTypes";
import { getRouteForLoggedInUser } from "router/helpers/router.functions";
import LoadingPageCounter from "../../views/components/LoadingPageCounter/LoadingPageCounter";

const PublicRoute = ({ children, route }: routerWrapperType) => {
  const auth = useAppSelector((state) => state.authentication);

  const restrictedRoute = route && route.restricted;

  if (auth.loggedIn && restrictedRoute) {
    {
      return <Navigate to={getRouteForLoggedInUser("admin")} />;
    }
  }

  return <Suspense fallback={<LoadingPageCounter />}>{children}</Suspense>;
};

export default PublicRoute;
