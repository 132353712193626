import { type FC, lazy } from "react";
import { lazyRetry } from "common/utility/Utils";
import { type routeType } from "router/routes/types";

const PositionsWrapper: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () => await import("../wrappers/PositionsWrapper/PositionsWrapper")
    )
);

const PositionsList: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () => await import("../pages/PositionsList/PositionsList")
    )
);

const PositionWizard: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () => await import("../pages/PositionWizard/PositionWizard")
    )
);

const positionsRoutes: routeType[] = [
  {
    path: "/positions",
    element: <PositionsWrapper />,
    permissions: "GET_POSITIONS",
    meta: {
      layout: "app",
      title: "Positions",
      type: "page",
      private: true,
      company: true,
      roles: ["zenhire"],
    },
    children: [
      {
        path: "",
        element: <PositionsList state={"active"} />,
        meta: {
          type: "component",
          company: true,
          private: true,
        },
      },
      {
        path: "draft",
        element: <PositionsList state={"draft"} />,
        meta: {
          type: "component",
          company: true,
          private: true,
        },
      },
      {
        path: "archived",
        element: <PositionsList state={"archived"} />,
        meta: {
          type: "component",
          company: true,
          private: true,
        },
      },
    ],
  },
  {
    path: "/create-position",
    element: <PositionWizard />,
    permissions: "CREATE_POSITION",
    meta: {
      layout: "app",
      title: "Create position",
      type: "page",
      company: true,
      private: true,
      roles: ["zenhire"],
    },
  },
];

export default positionsRoutes;
