import React, { type ReactNode } from 'react'
import {Navigate, useLocation, useParams} from 'react-router'
import { type routeMetaType } from 'router/routes/types'
import {useAuthorization} from "../../common/hooks/useAuthorization";
import {useAppSelector} from "../../redux/reduxTypes";

interface routeMiddlewareType {
  route: routeMetaType
  children: ReactNode
}

const RoleMiddleware = ({
  route,
  children
}: routeMiddlewareType) => {

  const { checkRoles } = useAuthorization()
  const location = useLocation();
  const auth = useAppSelector((state) => state.authentication);
  const key = useParams()

  if(checkRoles("company") && key.companyId != auth.userRole.company || checkRoles("company") && location.pathname === "/") {
    return <Navigate to={`/${auth.userRole.company}`} />
  } else {

  }

  return <>{children}</>
}

export default RoleMiddleware
