import { type ReactNode, useEffect } from "react";
import AdminNavbar from "views/components/AdminNavbar/AdminNavbar";
import ClientNavbar from "../../components/ClientNavbar/ClientNavbar";
import { useAppDispatch, useAppSelector } from "../../../redux/reduxTypes";
import { useNavigate, useParams } from "react-router";
import { getCompanyOverview } from "../../features/company/reducer/company.actions";
import { useAuthorization } from "../../../common/hooks/useAuthorization";
import { clearCompanyOverview } from "../../features/company/reducer/company.reducer";
import { isObjEmpty } from "common/utility/Utils";
import ErrorHandling from "views/components/ErrorHandling/ErrorHandling";
import { clearGlobalErrors } from "redux/global";

interface appLayoutType {
  children: ReactNode;
}

const AppLayout = ({ children }: appLayoutType) => {
  const key = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const companyOverview = useAppSelector(
    (state) => state.company.companyOverview
  );
  const { checkRoles } = useAuthorization();
  const actionError = useAppSelector(
    (state) => state.global.errors.actionError
  );

  useEffect(() => {
    if (key.companyId && !companyOverview.value) {
      dispatch(
        getCompanyOverview({
          id: key.companyId,
          roleAdmin: checkRoles("zenhire"),
        })
      );
    }
  }, [key.companyId]);

  useEffect(() => {
    if (companyOverview?.value?.state === "SUSPENDED") {
      navigate(`/${key.companyId}/suspended`);
    }
  }, [companyOverview?.value]);

  useEffect(() => {
    if (!key.companyId && companyOverview) {
      dispatch(clearCompanyOverview());
    }
  }, [key, companyOverview]);

  useEffect(() => {
    dispatch(clearGlobalErrors());
  }, [key]);

  useEffect(() => {}, [actionError]);

  return (
    <div className="app-layout flex flex-col h-screen">
      {checkRoles("zenhire") ? <AdminNavbar /> : null}

      {(companyOverview.value && key.companyId) || checkRoles("company") ? (
        <ClientNavbar
          companyData={companyOverview.value}
          idCompany={key.companyId as string}
        />
      ) : null}
      {isObjEmpty(companyOverview.error) ? (
        children
      ) : (
        <ErrorHandling error={companyOverview.error} />
      )}
      {/* Handle action errors in modal */}
      {!isObjEmpty(actionError) &&
        actionError &&
        (actionError.style === "MODAL" || actionError.style === "TOAST") && (
          <ErrorHandling error={actionError} />
        )}
    </div>
  );
};

export default AppLayout;
