// All reusable functions
import { type FC } from "react";
import { PhoneNumberUtil } from "google-libphonenumber";

export const isObjEmpty = (obj: any) => {
  if (!obj || Object.keys(obj).length === 0) {
    return true;
  } else {
    return false;
  }
};

export function capitalizeWords(input: string): string {
  return input
    .split(" ")
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join(" ");
}

export const isStringEmpty = (string: string) =>
  typeof string === "object" ||
  !string ||
  string.length === 0 ||
  string.trim().length === 0;

export const formatDate = (
  value: string,
  formatting?: {
    month: "numeric" | "2-digit" | "long" | "short" | "narrow" | undefined;
    day: "numeric" | "2-digit" | undefined;
    year: "numeric" | "2-digit" | undefined;
  },
  hideTime?: boolean
) => {
  if (!value) return value;

  var tempFormatting = formatting || {
    day: "numeric",
    month: "short",
    year: "numeric",
  };

  const date = new Date(value);
  const formattedDate = new Intl.DateTimeFormat("en-US", {
    ...tempFormatting,
    ...(hideTime ? {} : { hour: "2-digit", minute: "2-digit", hour12: false }),
    // timeZone: "UTC",
  }).format(date);

  // Check if the hour is incorrectly formatted as "24" and adjust it
  const parts = formattedDate.split(" ");

  if (parts[3] && parts[3].startsWith("24:")) {
    parts[3] = `00:${parts[3].slice(3)}`;
    return parts.join(" ");
  }

  return formattedDate;
};

export const formatFilterDate = (date: string) => {
  if (date) {
    const d = new Date(date);
    const year = new Intl.DateTimeFormat("en-US", { year: "numeric" }).format(
      d
    );
    const month = new Intl.DateTimeFormat("en-US", { month: "2-digit" }).format(
      d
    );
    const day = new Intl.DateTimeFormat("en-US", { day: "2-digit" }).format(d);

    return `${year}-${month}-${day}`;
  } else {
    return null;
  }
};

// function that formats number
// accepts raw number (value) and number of decimal (decimals) units to format
// if there isn't decimals parameter, formats number to 2 decimal units by default
// it should return only needed decimal units
// if decimals is set to 2 but th number is 1.10, it should return 1.1
export const formatPercentage = (value: number, decimals?: number) => {
  value = value * 100;
  if (decimals) {
    return Number(value.toFixed(decimals));
  } else {
    return Number(value.toFixed(0));
  }
  //   return Math.round(value * 10000) / 100;
};

export const formatQualifyingQuestionsScore = (value: number) => {
  return value ? "Qualified" : "Not Qualified";
};

export const validateStringLength = (
  string: string,
  min: number,
  max: number
) =>
  !(
    typeof string === "object" ||
    !string ||
    string.length >= max ||
    string.length < min
  );

export const isPasswordValid = (string: string) => {
  const regex = /^(?=.*\d)(?=.*?[#?!@$%^&*-])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

  return !(
    typeof string === "object" ||
    !string ||
    string.length === 0 ||
    string.trim().length === 0 ||
    !regex.test(string)
  );
};

export const validateEmail = (email: string): boolean => {
  if (
    String(email)
      .toLowerCase()
      .match(
        // old regex
        // /^[a-zA-Z0-9.\\-]+@[a-zA-Z0-9]+\.[a-zA-Z]{2,}$/

        // new regex
        /^[a-zA-Z0-9.\-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

        // /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
  ) {
    return true;
  } else {
    return false;
  }
};

export const validatePhoneNumber = (phone: string): boolean => {
  const phoneUtil = PhoneNumberUtil.getInstance();
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};

const hasRefreshed = () => {
  const refreshed: string | null = window.sessionStorage.getItem(
    "retry-lazy-refreshed"
  );
  return refreshed !== null ? JSON.parse(refreshed) : false;
};

// lazyRetry function prevents chunkLoad Errors if app is updated and client's page is not refreshed
// this works only with route-based code splitting
export const lazyRetry = function (
  componentImport: () => Promise<FC | any>
): Promise<FC | any> {
  return new Promise((resolve, reject) => {
    const refreshed: boolean = hasRefreshed();

    // try to import the component
    componentImport()
      .then((component: FC) => {
        window.sessionStorage.setItem("retry-lazy-refreshed", "false"); // success so reset the refresh
        resolve(component);
      })
      .catch((error: any) => {
        if (!refreshed) {
          // not been refreshed yet
          window.sessionStorage.setItem("retry-lazy-refreshed", "true"); // we are now going to refresh
          window.location.reload();
          return; // refresh the page
        }

        reject(() => {
          window.location.href = "/";
        }); // there was an error
        // window.location.href = "/";
      });
  });
};

export const isStringJSON = (string: string) => {
  try {
    JSON.parse(string);
    return true;
  } catch (err) {
    return false;
  }
};

export function getRandomId() {
  return Math.random().toString(36).substr(2, 5);
}

export function getRandomIntId() {
  return Math.floor(Math.random() * 90000) + 10000;
}

// takes a {} object and returns a FormData object
export var objectToFormData = function (obj: any, form?: any, namespace?: any) {
  var fd = form || new FormData();
  var formKey;

  for (var property in obj) {
    if (obj.hasOwnProperty(property)) {
      if (namespace) {
        formKey = namespace + "[" + property + "]";
      } else {
        formKey = property;
      }
      if (Array.isArray(obj[property]) && !(obj[property] instanceof File)) {
        // if array
        for (var i = 0; i < obj[property].length; i++) {
          let arrFormKey = formKey + "[" + i + "]";
          var el = obj[property][i];

          if (el instanceof File || typeof obj[property][i] !== "object") {
            fd.append(arrFormKey, el);
          } else {
            objectToFormData(el, fd, arrFormKey);
          }
        }
      } else {
        if (
          typeof obj[property] === "object" &&
          !(obj[property] instanceof File)
        ) {
          // if the property is an object, but not a File,
          // use recursivity.
          objectToFormData(obj[property], fd, formKey);
        } else {
          // if it's a string or a File object
          fd.append(formKey, obj[property]);
        }
      }
    }
  }

  return fd;
};

export const CAN_USE_DOM: boolean =
  typeof window !== "undefined" &&
  typeof window.document !== "undefined" &&
  typeof window.document.createElement !== "undefined";

export function millisToMinutesAndSeconds(millis: number) {
  const minutes: number = Math.floor(millis / 60000);
  const seconds: number = +((millis % 60000) / 1000).toFixed(0);

  const convertedTime: string =
    seconds == 60
      ? minutes + 1 + ":00"
      : minutes + ":" + (seconds < 10 ? "0" : "") + seconds;

  return convertedTime;
}

function str_pad_left(number: number, pad: string, length: number) {
  return (new Array(length + 1).join(pad) + number).slice(-length);
}

export function secondsToMinutesAndSeconds(secondsValue: number) {
  secondsValue = Math.round(secondsValue);
  const minutes: number = Math.floor(secondsValue / 60);
  const seconds = secondsValue - minutes * 60;

  const finalTime =
    str_pad_left(minutes, "0", 2) + ":" + str_pad_left(seconds, "0", 2);

  return finalTime;
}

export const formateSecondsToHHMMSS = (value: number) => {
  const formatedSecondsToHHMMSS = new Date(value * 1000)
    .toISOString()
    .slice(11, 19);

  return formatedSecondsToHHMMSS;
};

export function getBrowser(agent: string) {
  console.log(agent);
  let agentFormatted = agent.toLocaleLowerCase();
  switch (true) {
    case agentFormatted.indexOf("edge") > -1:
      return { value: "ms-edge", human: "MS Edge" };
    case agentFormatted.indexOf("edg/") > -1:
      return { value: "edge-chromium", human: "Edge" };
    case agentFormatted.indexOf("opr") > -1 ||
      navigator.userAgent.indexOf("OPR"):
      return { value: "opera", human: "Opera" };
    case agentFormatted.indexOf("chrome") > -1:
      return { value: "chrome", human: "Chrome" };
    case agentFormatted.indexOf("trident") > -1:
      return { value: "ms-ie", human: "MS IE" };
    case agentFormatted.indexOf("firefox") > -1:
      return { value: "mozilla", human: "Mozilla Firefox" };
    // case agentFormatted.indexOf("mozilla") > -1:
    //   return { value: "mozilla", human: "Mozilla Firefox" };
    case agentFormatted.indexOf("safari") > -1:
      return { value: "safari", human: "Safari" };
    default:
      return { value: "other", human: "" };
  }
}

export function getBrowserName() {
  const agent = window.navigator.userAgent.toLowerCase();

  return getBrowser(agent);
}

export const getTime = (value: string) => {
  if (!value) return value;
  let date = new Date(value);

  let hours = date.getHours();
  let minutes = date.getMinutes();

  return `${hours < 10 ? `0${hours}` : hours}:${
    minutes < 10 ? `0${minutes}` : minutes
  }`;
};

export const roleExists = (role: string, roles: string[]) => {
  for (let i = 0; i < roles.length; i++) {
    if (roles[i] === role) {
      return true;
    }
  }
  return false;
};

export const isDateValid = (givenDate: Date, expiredDate: Date) => {
  if (givenDate.getTime() <= expiredDate.getTime()) {
    return true;
  }
  return false;
};

export const splitStringUsingRegex = (inputString: string): string[] => {
  const characters: string[] = [];
  const regex = /[\s\S]/gu;

  let match;

  while ((match = regex.exec(inputString)) !== null) {
    characters.push(match[0]);
  }

  return characters;
};

export const checkIsTextHaveNodes = (value: string): boolean => {
  return value.includes(`"root":{"children"`) ? true : false;
};

export const getTimezoneOffset = (): string => {
  const date = new Date();
  return `${date.getTimezoneOffset()}`;
};

export const isArraysEqual = (a: any[], b: any[]) => {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  // If you don't care about the order of the elements inside
  // the array, you should sort both arrays here.
  // Please note that calling sort on an array will modify that array.
  // you might want to clone your array first.

  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
};
