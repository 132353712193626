import { lazy, type FC } from "react";
import { lazyRetry } from "common/utility/Utils";
import { type routeType } from "router/routes/types";
import { Navigate } from "react-router";

const Login: FC | any = lazy(
  async () => await lazyRetry(async () => await import("../pages/Login/Login"))
);
const ForgotPassword: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () => await import("../pages/ForgotPassword/ForgotPassword")
    )
);
const ForgotPasswordEmailSuccess: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () =>
        await import(
          "../components/ForgotPasswordEmailSuccess/ForgotPasswordEmailSuccess"
        )
    )
);
const ResetPassword: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () => await import("../pages/ResetPassword/ResetPassword")
    )
);
const VerifyEmail: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () => await import("../pages/VerifyEmail/VerifyEmail")
    )
);
const VerifyEmailSuccess: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () => await import("../pages/VerifyEmailSuccess/VerifyEmailSuccess")
    )
);
const SettingsWrapper: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () => await import("../wrappers/SettingsWrapper/SettingsWrapper")
    )
);
const SettingsGeneral: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () => await import("../pages/Settings/SettingsGeneral")
    )
);
const SettingsSecurity: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () => await import("../pages/Settings/SettingsSecurity")
    )
);

const authRoutes: routeType[] = [
  {
    path: "/login",
    element: <Login />,
    meta: {
      layout: "blank",
      title: "Login",
      type: "page",
      public: true,
      restricted: true,
    },
  },
  {
    path: "/auth/reset-password",
    element: <ForgotPassword />,
    meta: {
      layout: "blank",
      title: "Forgot Password",
      type: "page",
      public: true,
      restricted: true,
    },
  },
  {
    path: "/change-password",
    element: <ResetPassword />,
    meta: {
      layout: "blank",
      title: "Change Password",
      type: "page",
      shield: true,
    },
  },
  {
    path: "/auth/reset-password/success",
    element: <ForgotPasswordEmailSuccess />,
    meta: {
      layout: "blank",
      type: "page",
      title: "Forgot Password",
      public: true,
    },
  },
  {
    path: "/verify-email",
    element: <VerifyEmail />,
    meta: {
      layout: "blank",
      title: "Verify",
      type: "page",
      public: true,
      restricted: true,
    },
  },
  {
    path: "/verify-email/success",
    element: <VerifyEmailSuccess />,
    meta: {
      layout: "blank",
      title: "Verified",
      type: "page",
      public: true,
      restricted: true,
    },
  },
  {
    path: "/profile-settings",
    element: <SettingsWrapper />,
    permissions: "UPDATE_USER",
    meta: {
      layout: "app",
      title: "User settings",
      type: "page",
      private: true,
      company: true,
      roles: ["zenhire"],
    },
    children: [
      {
        path: "",
        element: <Navigate to="general" replace />,
        meta: {
          layout: "blank",
          type: "page",
          private: true,
          company: true,
        },
      },
      {
        path: "general",
        element: <SettingsGeneral />,
        meta: {
          title: "General",
          type: "component",
          private: true,
          company: true,
        },
      },
      {
        path: "security",
        element: <SettingsSecurity />,
        meta: {
          title: "Security",
          type: "component",
          private: true,
          company: true,
        },
      },
    ],
  },
];

export default authRoutes;
