import { isObjEmpty } from "common/utility/Utils";
import { Outlet } from "react-router";
import { useAppSelector } from "redux/reduxTypes";
import ErrorHandling from "views/components/ErrorHandling/ErrorHandling";

const BlankLayout = () => {
  const actionError = useAppSelector(
    (state) => state.global.errors.actionError,
  );

  return (
    <div className="app-layout">
      <Outlet />
      {!isObjEmpty(actionError) &&
        actionError &&
        (actionError.style === "MODAL" || actionError.style === "TOAST") && (
          <ErrorHandling error={actionError} />
        )}
    </div>
  );
};

export default BlankLayout;
