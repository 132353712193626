import { forwardRef, useRef } from "react";
import { ReactComponent as CalendarIco } from "assets/icons/calendar.svg";
import { ReactComponent as ClearIco } from "assets/icons/discard-ico.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./assets/datePickerInput.css";
interface propTypes {
  label?: string;
  onChange?: (value: Date | null) => void;
  placeholder?: string;
  dateFormat?: string;
  showTimeInput?: boolean;
  type?: string;
  minDate?: Date;
  date: Date | null;
  disabled?: boolean;
  activeClass?: boolean;
  wrapperDatePickerClassName?: string;
  iconClassName?: string;
  inputClassName?: string;
}
const DatepickerInput = ({
  date,
  onChange,
  placeholder,
  label,
  minDate,
  showTimeInput,
  type = "",
  disabled = false,
  activeClass = false,
  wrapperDatePickerClassName,
  iconClassName,
  inputClassName,
}: propTypes) => {
  const defaultClass = "border-[1px] border-[#777777] text-black min-w-[330px]";
  const filterClass = `${
    date && activeClass
      ? "bg-main-color-light text-main-color"
      : "bg-ultra-light-gray text-light-gray"
  } min-w-[180px]`;
  const refCustomInput = useRef() as React.MutableRefObject<HTMLDivElement>;
  const handleChange = (e: any, date: Date | null) => {
    e.stopPropagation();
    if (!onChange) return;
    onChange(date);
  };

  const CustomInput = forwardRef(
    (props: any, ref: React.Ref<HTMLDivElement>) => {
      return (
        <div
          className={`${inputClassName ? inputClassName : ""}
                     rounded-[10px] py-2 px-4 mr-auto flex flex-row justify-between gap-5 items-center relative h-full  
                     ${type === "filter" ? filterClass : defaultClass}
                     ${
                       disabled
                         ? "select-none cursor-not-allowed bg-[#F2F2F2]"
                         : "cursor-pointer"
                     }
                 `}
          ref={ref}
          onClick={() => props.onClick()}
        >
          <span>
            {props.value ? props.value : props.placeholder || "Select Date"}
          </span>
          <div className={"flex justify-center items-center gap-1"}>
            {props.value && !disabled ? (
              <ClearIco
                className={`[&_path]:fill-main-color`}
                onClick={(e) => handleChange(e, null)}
              />
            ) : null}
            <CalendarIco
              className={`${iconClassName ? iconClassName : ""} ${
                date && activeClass
                  ? "[&_path]:fill-main-color"
                  : "[&_path]:fill-light-gray"
              }`}
            />
          </div>
        </div>
      );
    },
  );

  return (
    <div>
      {label ? (
        <span className={"text-sm text-[#838383] block pb-3 select-none"}>
          {label}
        </span>
      ) : null}
      <div className={"zh-datePicker"}>
        <DatePicker
          placeholderText={placeholder}
          selected={date}
          minDate={minDate || null}
          onChange={(date, e) => handleChange(e, date || null)}
          dateFormat={"MMM dd, yyyy"}
          showTimeInput={showTimeInput || false}
          customInput={<CustomInput ref={refCustomInput} />}
          disabledKeyboardNavigation={true}
          disabled={disabled}
          popperProps={{ strategy: "fixed" }}
          wrapperClassName={`${wrapperDatePickerClassName ? wrapperDatePickerClassName : ""}`}
        />
      </div>
    </div>
  );
};

export default DatepickerInput;
