// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";

interface filtersType {
  filtersOptions: any;
}

const initialState: filtersType = {
  filtersOptions: {},
};
export const globalFiltersOptionsSlice = createSlice({
  name: "globalFilterOptions",
  initialState,

  reducers: {
    setFiltersOptions: (state, action) => {
      state.filtersOptions = {
        ...state.filtersOptions,
        [action.payload.flag]: [...action.payload.data],
      };
    },
    clearFiltersOptions: (state) => {
      state.filtersOptions = initialState.filtersOptions;
    },
  },
  extraReducers: (builder) => {},
});
export const { setFiltersOptions, clearFiltersOptions } =
  globalFiltersOptionsSlice.actions;
export default globalFiltersOptionsSlice.reducer;
