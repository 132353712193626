import { type FC, lazy } from "react";
import { lazyRetry } from "common/utility/Utils";
import { type routeType } from "router/routes/types";

const SystemEmailsWrapper: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () =>
        await import("../wrappers/SystemEmailsWrapper/SystemEmailsWrapper")
    )
);

const SystemEmails: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () => await import("../pages/SystemEmails/SystemEmails")
    )
);

const SystemEmailTemplatesEditor: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () =>
        await import(
          "../pages/SystemEmailTemplatesEditor/SystemEmailTemplatesEditor"
        )
    )
);

const systemEmails: routeType[] = [
  {
    path: "/system-emails",
    element: <SystemEmailsWrapper />,
    allPermissionsCheck: [
      "SYSTEM_EMAIL_TEMPLATE_GET",
      "SYSTEM_EMAIL_TEMPLATE_UPDATE",
    ],
    meta: {
      layout: "app",
      title: "Emails",
      type: "page",
      private: true,
      roles: ["zenhire"],
    },
    children: [
      {
        path: "",
        element: <SystemEmails />,
        meta: {
          type: "component",
          private: true,
        },
      },
      // System Email Template Editor
      {
        path: "email-template-editor/:emailType",
        element: <SystemEmailTemplatesEditor />,
        meta: {
          title: "Email template editor",
          type: "component",
          private: true,
        },
      },
    ],
  },
];

export default systemEmails;
