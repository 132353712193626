export function checkIsFinished(state: string) {
  switch (state) {
    case "COMPLETED":
    case "UNSCORED":
    case "HIRED":
    case "REJECTED":
      return true;
    default:
      return false;
  }
}
