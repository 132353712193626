import { tokenTypes } from "common/types/api.types";

// Stores JWT in local storage
export function storeKey(payload: tokenTypes) {
  if (payload.access_token) {
    localStorage.setItem(
      "zen_access_token",
      JSON.stringify(payload.access_token)
    );
  }
  if (payload.refresh_token) {
    localStorage.setItem(
      "zen_refresh_token",
      JSON.stringify(payload.refresh_token)
    );
  }
}

export function storeTestCandidateKey(payload: tokenTypes) {
  if (payload.access_token) {
    localStorage.setItem(
      "zen_applicant_access_token",
      JSON.stringify(payload.access_token)
    );
  }
  if (payload.refresh_token) {
    localStorage.setItem(
      "zen_candidate_refresh_token",
      JSON.stringify(payload.refresh_token)
    );
  }
}

export function removeKey(key: string) {
  if (key) {
    localStorage.removeItem(key);
  }
}
