import { createSlice } from "@reduxjs/toolkit";
import { getApplicants } from "./applicants.actions";
import { applicantsListReducerType } from "../applicants.types";
interface ReducerType {
  applicantsList: applicantsListReducerType;
}

const initialState: ReducerType = {
  applicantsList: {
    values: null,
    loading: true,
    error: null,
  },
};

export const applicants = createSlice({
  name: "applicants",
  initialState,
  reducers: {
    clearApplicantsList: (state) => {
      state.applicantsList = initialState.applicantsList;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getApplicants.pending, (state) => {
        state.applicantsList.loading = true;
      })
      .addCase(getApplicants.fulfilled, (state, { payload }) => {
        state.applicantsList.values = payload;
        state.applicantsList.loading = false;
      })
      .addCase(getApplicants.rejected, (state, { payload }: any) => {
        state.applicantsList.error =
          payload?.code === "ERR_CANCELED" ? null : payload;
        state.applicantsList.loading = payload?.code === "ERR_CANCELED";
      });
  },
});

export const { clearApplicantsList } = applicants.actions;

export default applicants.reducer;
