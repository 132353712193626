import { createAsyncThunk } from "@reduxjs/toolkit";
// ** Axios Imports
import { http } from "common/api/http";
import { type microdimensionWithTestsType } from "../microdimensions.types";
import { fetchMicrodimensions } from "./microdimensions.services";
import { setToast } from "redux/alert";
import { RootState } from "redux/store";

export const getMicrodimensions = createAsyncThunk<
  any,
  {
    paramsArray?: any;
    page: number;
    companyMicrodimensions?: boolean;
    signal?: any;
  },
  { state: RootState }
>(
  "microdimensionsLibrary/getMicrodimensions",
  async (
    { page, companyMicrodimensions, paramsArray, signal },
    { rejectWithValue }
  ) => {
    try {
      const filtersParams = {
        ...(paramsArray?.filters?.direction && {
          direction: paramsArray?.filters.direction.value,
        }),
        ...(paramsArray?.filters?.type && {
          type: paramsArray?.filters.type.value,
        }),
        ...(paramsArray?.search && {
          search: paramsArray?.search,
        }),
        ...(paramsArray?.filters?.sort && {
          sort: paramsArray?.filters.sort.value,
        }),
        ...(companyMicrodimensions && {
          custom: true,
        }),
        ...(paramsArray?.filters?.company && {
          companyId: paramsArray?.filters.company._id,
        }),
      };

      const response = await fetchMicrodimensions({
        page: page,
        ...filtersParams,
        ...(signal && {
          signal: signal,
        }),
      });
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "CUSTOM",
        style: "MAIN_CONTENT",
      });
    }
  }
);

export const createMicrodimension = createAsyncThunk(
  "microdimensionsLibrary/createMicrodimension",
  async (
    {
      name,
      description,
      icon,
      type,
      scoreCategories,
    }: microdimensionWithTestsType,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await http.post("/microdimensions", {
        name,
        description,
        type,
        scoreCategories,
      });
      dispatch(
        setToast({
          message: "Successfully created a new competency.",
          type: "success",
        })
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "FORM",
        style: "INFO_BOX",
      });
    }
  }
);

export const updateMicrodimension = createAsyncThunk(
  "microdimensionsLibrary/updateMicrodimension",
  async (
    {
      _id,
      name,
      description,
      icon,
      type,
      scoreCategories,
    }: microdimensionWithTestsType,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await http.patch(`/microdimensions/${_id}`, {
        name,
        description,
        icon,
        type,
        scoreCategories: scoreCategories?.length ? scoreCategories : null,
      });
      dispatch(
        setToast({
          message: "The competency is successfully updated.",
          type: "success",
        })
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "FORM",
        style: "INFO_BOX",
      });
    }
  }
);

export const deleteMicrodimension = createAsyncThunk<
  any,
  { id: string | null },
  { state: RootState }
>(
  "microdimensionsLibrary/deleteMicrodimension",
  async ({ id }, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await http.delete(`/microdimensions/${id}`);
      const state = getState();
      const values = state.microdimensions.microdimensionsList.values;
      const filters = state.microdimensions.microdimensionsList.filters;

      const page =
        values?.page && values?.total && values?.limit
          ? values.total > values.page * values.limit - values.limit
            ? values.page
            : values.page - 1
          : 1;
      const microdimensions = await fetchMicrodimensions({
        page: page,
        ...filters,
      });
      const newData: any = {
        ...microdimensions.data,
        data: values ? values.data.filter((v) => v._id !== id) : [],
      };

      dispatch(setToast({ message: response.data.message, type: "success" }));
      return newData;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "ACTION",
        style: "TOAST",
      });
    }
  }
);
