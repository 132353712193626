import { lazy, type FC } from "react";
import { lazyRetry } from "common/utility/Utils";
import BlankLayout from "views/layouts/BlankLayout/BlankLayout";
import { type routeType } from "router/routes/types";

const Error: FC | any = lazy(
  async () => await lazyRetry(async () => await import("../pages/Error"))
);
const NotFound: FC | any = lazy(
  async () => await lazyRetry(async () => await import("../pages/NotFound"))
);
const NoInternet: FC | any = lazy(
  async () => await lazyRetry(async () => await import("../pages/NoInternet"))
);
const NotAuthorized: FC | any = lazy(
  async () =>
    await lazyRetry(async () => await import("../pages/NotAuthorized"))
);
const NotSupported: FC | any = lazy(
  async () => await lazyRetry(async () => await import("../pages/NotSupported"))
);
const DownloadFile: FC | any = lazy(
  async () => await lazyRetry(async () => await import("../pages/DownloadFile"))
);

const exceptionRoutes: routeType[] = [
  {
    path: "/error",
    element: <Error />,
    meta: {
      layout: "blank",
      type: "page",
      title: "Login",
      public: true,
    },
  },
  {
    path: "/not-found",
    element: <NotFound />,
    meta: {
      layout: "blank",
      type: "page",
      title: "Not Found",
      public: true,
    },
  },
  {
    path: "/no-internet",
    element: <NoInternet />,
    meta: {
      layout: "blank",
      type: "page",
      title: "No internet",
      public: true,
    },
  },
  {
    path: "/not-authorized",
    element: <NotAuthorized />,
    meta: {
      layout: "blank",
      type: "page",
      title: "Not authorized",
      public: true,
    },
  },
  {
    path: "/not-supported",
    element: <NotSupported />,
    meta: {
      layout: "blank",
      type: "page",
      title: "Not supported",
      public: true,
    },
  },
  {
    path: "/download-file/:path",
    element: <DownloadFile />,
    meta: {
      layout: "blank",
      type: "page",
      title: "Download File",
      private: true,
    },
  },

  {
    path: "*",
    element: <BlankLayout />,
    meta: {},
    children: [
      { path: "*", element: <NotFound />, meta: { title: "Not found" } },
    ],
  },
];

export default exceptionRoutes;
