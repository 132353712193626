import { ElementType, MouseEvent, useMemo, useState } from "react";
// Icons
import savedPrimary from "assets/icons/saved-primary.svg";
import savedSecondary from "assets/icons/saved-secondary.svg";
import Spinner from "../spinners/Spinner";

type ButtonProps = {
  type: "Primary" | "Secondary" | "Naked" | "Export";
  tag?: ElementType;
  text: string;
  state?: "Loading" | "Confirmation" | "";
  icon?: string;
  target?: string;
  disabled?: boolean;
  className?: string;
  isSubmit?: boolean;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  to?: string;
  style?: any;
  FunctionIcon?: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
};
const Button = ({
  type,
  tag,
  text,
  state,
  icon,
  disabled,
  target,
  className,
  isSubmit,
  onClick,
  to,
  style,
  FunctionIcon,
}: ButtonProps) => {
  const [buttonStyle, setButtonStyle] = useState("");
  const iconSize = `max-w-[22px] max-h-[22px]`;

  const Tag = useMemo(() => {
    return tag && !disabled ? tag : "button";
  }, [tag, disabled]);

  const tagAttr = useMemo(() => {
    let attr = {};
    if (disabled) {
      attr = {
        ...attr,
        disabled: true,
      };
    }
    if (to) {
      attr = {
        ...attr,
        to: to,
        target: target || "",
      };
    } else if (onClick || isSubmit) {
      attr = {
        ...attr,
        onClick: onClick,
        type: isSubmit ? "submit" : "button",
      };
    }
    return attr;
  }, [tag, onClick, disabled]);

  // hover:bg-[#5A9668] hover:border-[#5A9668]
  // active:bg-[#3A6744] active:border-[#3A6744]

  useMemo(() => {
    let style = "";
    switch (type) {
      case "Primary":
        style = ` 
          relative flex justify-center items-center cursor-pointer gap-2 bg-main-color border-main-color border-2 text-white rounded py-1 px-2 select-none
          hover:opacity-[0.85] active:opacity-[0.95]
          disabled:hover:opacity-[1]
          disabled:bg-[#BFBFBF] disabled:border-[#BFBFBF]
        `;
        break;
      case "Secondary":
        style = `
          relative flex justify-center items-center cursor-pointer gap-2 bg-transparent border-main-color border-2 text-main-color rounded py-1 px-2 select-none
          hover:opacity-80
          active:bg-[#D8D8D8]
          disabled:text-[#BFBFBF] disabled:border-[#BFBFBF] disabled:bg-transparent
        `;
        break;
      case "Naked":
        style = `flex justify-center items-center cursor-pointer gap-2 bg-transparent border-transparent border-2 text-main-color rounded py-1 px-2 select-none`;

        break;
      case "Export":
        style = `
          relative flex justify-center items-center gap-2 px-3 p-2 min-w-[206px cursor-pointer bg-black rounded cursor-pointer text-white
          disabled:cursor-default
          disabled:opacity-90
          disabled:cursor-not-allowed
        `;

        break;
    }
    setButtonStyle(`${className} ${style}`);
  }, [type]);

  return (
    <>
      {type === "Primary" && (
        <Tag style={style} className={`${buttonStyle}`} {...tagAttr}>
          {state === "Loading" && (
            <>
              {icon && (
                <img
                  src={icon}
                  className={`${iconSize} invisible`}
                  alt="saved-primary"
                />
              )}
              <span className="invisible">{text}</span>
              <div className="absolute top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%]">
                <Spinner classIcon={"w-[23px] !fill-[#a3a3a3]"} />
              </div>
            </>
          )}
          {state === "Confirmation" && (
            <>
              {icon && (
                <img
                  src={savedPrimary}
                  className={`${iconSize}`}
                  alt="saved-primary"
                />
              )}
              {text}
            </>
          )}
          {!state && (
            <>
              {FunctionIcon && <FunctionIcon className={`${iconSize}`} />}
              {icon && (
                <img src={icon} className={`${iconSize}`} alt="btn-ico" />
              )}
              {text}
            </>
          )}
        </Tag>
      )}

      {type === "Secondary" && (
        <Tag className={`${buttonStyle}`} {...tagAttr}>
          {state === "Loading" && (
            <>
              {FunctionIcon && (
                <FunctionIcon className={`${iconSize} invisible`} />
              )}
              {icon && (
                <img
                  src={icon}
                  className={`${iconSize} invisible`}
                  alt="saved-primary"
                />
              )}
              <span className="invisible">{text}</span>
              <div className="absolute top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%]">
                <Spinner classIcon={"w-[23px] !fill-[#a3a3a3]"} />
              </div>
            </>
          )}
          {state === "Confirmation" && (
            <>
              {icon && (
                <img
                  src={savedSecondary}
                  className={`${iconSize}`}
                  alt="saved-secondary"
                />
              )}
              {text}
            </>
          )}
          {!state && (
            <>
              {FunctionIcon && (
                <FunctionIcon
                  className={`${iconSize} ${iconSize} ${
                    disabled
                      ? "[&_path]:fill-[#5c5c5c]"
                      : "[&_path]:fill-main-color"
                  }`}
                />
              )}
              {icon && (
                <img
                  src={icon}
                  className={`${iconSize} ${disabled ? "grayscale" : ""}`}
                  alt="btn-ico"
                />
              )}
              {text}
            </>
          )}
        </Tag>
      )}

      {type === "Naked" && (
        <Tag className={`${buttonStyle}`} {...tagAttr}>
          {FunctionIcon && (
            <FunctionIcon
              className={`${iconSize} ${iconSize} ${
                disabled
                  ? "[&_path]:fill-[#5c5c5c]"
                  : "[&_path]:fill-main-color"
              }`}
            />
          )}
          {icon && <img src={icon} className={`${iconSize}`} alt="btn-ico" />}
          {text}
        </Tag>
      )}
    </>
  );
};

export default Button;
