import { type FC, lazy } from "react";
import { lazyRetry } from "common/utility/Utils";
import { type routeType } from "router/routes/types";

const CompanySettingsWrapper: FC | any = lazy(
  async () =>
    await lazyRetry(async () => await import("../wrappers/SettingsWrapper"))
);
const CompanySettingsInfo: FC | any = lazy(
  async () =>
    await lazyRetry(async () => await import("../pages/CompanySettingsInfo"))
);
const CompanySettingsIdentity: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () => await import("../pages/CompanySettingsIdentity")
    )
);

const companyAuthRoutes: routeType[] = [
  {
    path: "/settings",
    element: <CompanySettingsWrapper />,
    permissions: "UPDATE_COMPANY",
    meta: {
      layout: "app",
      type: "page",
      company: true,
      private: true,
    },
    children: [
      {
        path: "",
        element: <CompanySettingsInfo />,
        meta: {
          title: "Company Info",
          type: "component",
          company: true,
          private: true,
        },
      },
      {
        path: "visual-identity",
        element: <CompanySettingsIdentity />,
        meta: {
          title: "Visual Identity",
          type: "component",
          company: true,
          private: true,
        },
      },
    ],
  },
];
export default companyAuthRoutes;
