import { FunctionComponent, useMemo } from "react";
import { useLocation } from "react-router";
import { ReactComponent as FollowIco } from "assets/icons/follow-border-ico.svg";
import { ReactComponent as FollowingIco } from "assets/icons/follow-fill-ico.svg";
import { useAppDispatch, useAppSelector } from "../../../../redux/reduxTypes";
import { setGlobalFilters } from "../../../../redux/globalFilters";
import ApplicantTransferFollow from "../../../features/applicant/components/ApplicantFollowActions/components/ApplicantTransferFollow";

type typeProps = {
  loading: boolean;
  totalFollowedByMe: number;
  totalNotFollowed: number;
};
const AdditionalFiltersFollowing: FunctionComponent<typeProps> = (props) => {
  const { loading, totalFollowedByMe, totalNotFollowed } = props;

  const dispatch = useAppDispatch();
  const location = useLocation();

  const globalFilters: any = useAppSelector(
    (state) => state.globalFilters[location.pathname],
  );
  const authUser = useAppSelector(
    (state) => state.authentication.userData?.value,
  );
  const applyFollowingFilters = (
    followerArray: string[] | "notFollowed" | null,
  ) => {
    dispatch(
      setGlobalFilters({
        location: location.pathname,
        filters: {
          ...globalFilters?.filters,
          followerIds: followerArray,
        },
        page: 1,
      }),
    );
  };

  const followerIds = useMemo(() => {
    return globalFilters?.filters?.followerIds;
  }, [globalFilters?.filters]);

  return (
    <div className={"flex flex-col justify-start items-start gap-1.5 "}>
      <div
        onClick={() =>
          applyFollowingFilters(
            authUser && !followerIds?.includes(authUser?._id)
              ? [authUser?._id]
              : null,
          )
        }
        className={`w-full cursor-pointer flex justify-between items-center text-sm font-[400] px-[6px] py-[5px] rounded-[10px] ${followerIds && followerIds !== "notFollowed" && followerIds[0] === authUser?._id ? "bg-z-orange-light text-z-orange" : "hover:bg-z-grey-100 text-z-grey-550 [&_path]:fill-z-grey-550"}`}
      >
        <div className={"flex gap-1.5 items-center"}>
          <FollowingIco
            className={`max-w-[20px] ${followerIds && followerIds !== "notFollowed" && followerIds[0] === authUser?._id ? "[&_path]:fill-z-orange" : "[&_path]:fill-z-grey-550"}`}
          />
          Followed by me
        </div>
        <span className={""}>({totalFollowedByMe || 0})</span>
      </div>

      <div
        onClick={() =>
          applyFollowingFilters(
            followerIds !== "notFollowed" ? "notFollowed" : null,
          )
        }
        className={`w-full cursor-pointer flex gap-1.5 justify-between items-center text-sm font-[400] px-[6px] py-[5px] rounded-[10px] ${followerIds && followerIds === "notFollowed" ? "bg-z-green-light text-z-green" : "hover:bg-z-grey-100 text-z-grey-550 [&_path]:fill-z-grey-550"}`}
      >
        <div className={"flex gap-1.5 items-center"}>
          <FollowIco
            className={`max-w-[20px] ${followerIds && followerIds === "notFollowed" ? "[&_path]:fill-z-green" : "[&_path]:fill-z-grey-550"}`}
          />
          Not followed
        </div>
        <span className={""}>({totalNotFollowed || 0})</span>
      </div>
      <div className={"mt-3"}>
        <ApplicantTransferFollow
          selectedUsers={followerIds || []}
          setSelectedUsers={(value: string[]) =>
            applyFollowingFilters(value?.length ? value : null)
          }
          type={"multiple"}
          spinnerClassName={"max-w-[20px]"}
          hideInfo
        />
      </div>
    </div>
  );
};

export default AdditionalFiltersFollowing;
