import { createAsyncThunk } from "@reduxjs/toolkit";
// ** Axios Imports
import { RootState } from "redux/store";
import { fetchApplicants } from "./applicants.services";
type multiSelectType = {
  _id: string;
};
export const getApplicants = createAsyncThunk<
  any,
  {
    companyId?: string;
    signal?: any;
    paramsArray?: any;
  },
  { state: RootState }
>(
  "applicants/getApplicants",
  async ({ companyId, paramsArray, signal }, { rejectWithValue }) => {
    try {
      const params = {
        ...(paramsArray?.filters?.tests && {
          tests: `${paramsArray?.filters?.tests.map((tests: multiSelectType) => tests._id)}`,
        }),
        ...(paramsArray?.search && {
          search: paramsArray?.search,
        }),
        ...(paramsArray?.filters?.status && {
          status: paramsArray?.filters?.status.value,
        }),
        ...(paramsArray?.filters?.company && {
          company: paramsArray?.filters?.company?.value,
        }),
        ...(paramsArray?.filters?.microdimensions && {
          microdimensions: `${paramsArray?.filters?.microdimensions.map(
            (microdimension: multiSelectType) => microdimension._id,
          )}`,
        }),
        ...(paramsArray?.filters?.positions && {
          positions: `${paramsArray?.filters?.positions.map(
            (position: multiSelectType) => position._id,
          )}`,
        }),
        ...(paramsArray?.sort && {
          sort: paramsArray?.sort,
        }),
        page: paramsArray?.page || 1,
        limit: paramsArray?.limit || 10,
        ...(companyId && {
          company: companyId,
        }),
      };
      const response = await fetchApplicants(params, signal);

      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "CUSTOM",
        style: "MAIN_CONTENT",
      });
    }
  },
);
