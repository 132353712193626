import { lazy } from "react";
import { lazyRetry } from "common/utility/Utils";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { getRoutes } from "./routes";

const App: React.FC | any = lazy(
  async () => await lazyRetry(async () => await import("App"))
);

const router = createBrowserRouter([
  { path: "", element: <App />, children: [...getRoutes()] },
]);

const Router = () => {
  return <RouterProvider router={router} />;
};

export default Router;
