// ** Redux Imports
import { createAsyncThunk } from "@reduxjs/toolkit";

// ** Axios Imports
import { http } from "common/api/http";
import { setToast } from "redux/alert";
import { RootState } from "redux/store";

interface getTestsParams {
  state: string;
  companyId?: string;
  paramsArray?: any;
  signal?: any;
}

type multiSelectType = {
  _id: string;
};

export const getPositions = createAsyncThunk<
  any,
  getTestsParams,
  { state: RootState }
>(
  "positions/getPositions",
  async (
    { state, companyId, paramsArray, signal },
    { getState, rejectWithValue, dispatch },
  ) => {
    try {
      const params = {
        ...(paramsArray?.filters?.company && {
          companyId: paramsArray?.filters.company?.value,
        }),
        ...(paramsArray?.search && {
          search: paramsArray?.search,
        }),
        ...(paramsArray?.filters?.tests && {
          tests: `${paramsArray?.filters.tests.map((tests: multiSelectType) => tests._id)}`,
        }),
        ...(paramsArray?.sort && {
          sort: paramsArray?.sort,
        }),
        page: paramsArray?.page || 1,
        ...(state && {
          state: `${state.toUpperCase()}`,
        }),
        limit: paramsArray?.limit || 10,
        ...(companyId && {
          company: companyId,
        }),
        ...((paramsArray?.filters?.followerIds === "notFollowed" ||
          paramsArray?.filters?.followerIds?.length) && {
          followersIds:
            paramsArray?.filters?.followerIds === "notFollowed"
              ? "null"
              : `${paramsArray?.filters?.followerIds.map((item: string) => {
                  return item;
                })}`,
        }),
      };
      const response = await http.get(`/positions`, {
        params,
        signal,
      });
      if (!response?.data) return;
      return response?.data;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "CUSTOM",
        style: "MAIN_CONTENT",
      });
    }
  },
);

export interface positionWizardInput {
  name: string;
  hiringLimit: number;
  employmentType: string;
  workingFrom: string;
  location?: string;
  companyId?: string;
  requirements?: {
    licenses?: { importance: number; licenses: string[] };
    industryExperience?: { importance: number; industries: string[] };
    workExperience?: { from: number; to: number; importance: number };
    education?: {
      level: string;
      fieldOfStudy: string;
      importance: number;
    };
  };
  jobDescription?: string;
  jobSummary?: string;
  keyResponsibilities?: string;
}

export const createPosition = createAsyncThunk(
  "positions/createPosition",
  async (data: any, { dispatch, rejectWithValue }) => {
    try {
      const response = await http.post("/positions", data);
      dispatch(
        setToast({
          message: "Successfully created a position.",
          type: "success",
        }),
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "FORM",
        style: "INFO_BOX",
      });
    }
  },
);
