import { errorRequestType } from "common/types/data.types";

export const employmentTypeOptions = [
  {
    label: "Full-Time",
    value: "Full-Time",
  },
  {
    label: "Part-Time",
    value: "Part-Time",
  },
  {
    label: "Contract",
    value: "Contract",
  },
  {
    label: "Consulting",
    value: "Consulting",
  },
];
export const workFromOptions = [
  {
    label: "On-site",
    value: "on-site",
  },
  {
    label: "Remote",
    value: "Remote",
  },
  {
    label: "Hybrid",
    value: "hybrid",
  },
];
export const salaryPeriodOptions = [
  {
    label: "Monthly",
    value: "month",
  },
  {
    label: "Yearly",
    value: "year",
  },
  {
    label: "Daily",
    value: "day",
  },
  {
    label: "Hourly",
    value: "hour",
  },
];

export const supportedMimeTypesChrome = [
  "video/webm;codecs=vp9",
  "video/webm;codecs=VP9",
  "video/webm;codecs=vp8,opus",
  "video/WEBM;codecs=VP8,OPUS",
  "video/mp4;codecs=avc1",
  "video/mp4;codecs=AVC1",
];
export const supportedMimeTypesMozilla = [
  "video/webm;codecs=vp8,opus",
  "video/WEBM;codecs=VP8,OPUS",
  "video/webm;codecs=opus",
  "video/webm;codecs=OPUS",
];
export const supportedMimeTypesSafari = [
  "video/mp4;codecs=avc1",
  "video/mp4;codecs=AVC1",
];
export const supportedMimeTypesEdge = [
  "video/webm;codecs=vp9",
  "video/webm;codecs=VP9",
  "video/webm;codecs=opus",
  "video/webm;codecs=OPUS",
];
// maybe to merge audio and video supportedMimeTypes per browser in the future
export const supportedAudioMimeTypesChrome = [
  "audio/webm;codecs=opus",
  "audio/webm;codecs=pcm",
];
export const supportedAudioMimeTypesMozilla = [
  "audio/webm;codecs=opus",
  "audio/ogg;codecs=opus",
];
export const supportedAudioMimeTypesSafari = [
  "audio/mp4;codecs=avc1",
  "audio/mp4;codecs=mp4a",
];
export const supportedAudioMimeTypesEdge = [
  "audio/webm;codecs=opus",
  "audio/ogg;codecs=opus",
];

export const videoTypes = ["webm", "mp4"];
export const codecs = [
  "vp9",
  "vp8",
  "avc1",
  "h264",
  "h.264",
  "opus",
  "vp8.0",
  "vorbis",
  "pcm",
  "aac",
  "mpeg",
  "mp4a",
  "should-not-be-supported",
];
export const audioTypes = ["webm", "mp4", "wav", "ogg"];

export const errorRequestConstant: errorRequestType = {
  isError: false,
  message: "",
  type: "",
};
