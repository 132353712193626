// You can customize the template with the help of this file

interface themeConfigType {
  app: {
    appName: string
    appLogoImage: any
    appSmallLogoImage: any
  }
  layout: {
    menu: {
      isHidden: boolean
      isCollapsed: boolean
    }
    scrollTop: boolean // Enable scroll to top button
    toastPosition:
    | 'top-left'
    | 'top-center'
    | 'top-right'
    | 'bottom-left'
    | 'bottom-center'
    | 'bottom-right'
  }
}

// Template config options
const themeConfig: themeConfigType = {
  app: {
    appName: 'ZenHire',
    appLogoImage: require('assets/images/ZenHire-logo.png').default,
    appSmallLogoImage: require('assets/images/ZenHire-logo.png').default
  },
  layout: {
    menu: {
      isHidden: false,
      isCollapsed: false
    },
    scrollTop: true, // Enable scroll to top button
    toastPosition: 'bottom-left'
  }
}

export default themeConfig
