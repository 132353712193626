import { lazy, type FC } from "react";
import { lazyRetry } from "common/utility/Utils";
import { type routeType } from "router/routes/types";

const ApplicantWrapper: FC | any = lazy(
  async () =>
    await lazyRetry(async () => await import("../wrappers/ApplicantWrapper"))
);
const ApplicantApplicationWrapper: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () => await import("../wrappers/ApplicantApplicationWrapper")
    )
);

const ApplicantProfile: FC | any = lazy(
  async () =>
    await lazyRetry(async () => await import("../pages/ApplicantProfile"))
);

const ApplicantApplicationStepPage: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () => await import("../pages/ApplicantApplicationStepPage")
    )
);

const ApplicantManualScoringTestPage: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () => await import("../pages/ApplicantManualScoringTestPage")
    )
);

const ApplicantPreviewFile: FC | any = lazy(
  async () =>
    await lazyRetry(async () => await import("../pages/ApplicantPreviewFile"))
);

const ApplicantManualScoringLiveInterview: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () => await import("../pages/ApplicantManualScoringLiveInterview")
    )
);

const ApplicantReport: FC | any = lazy(
  async () =>
    await lazyRetry(async () => await import("../pages/ApplicantReport"))
);

const applicantRoutes: routeType[] = [
  {
    path: "/applicant/:id",
    element: <ApplicantWrapper />,
    permissions: ["GET_APPLICANTS"],
    meta: {
      layout: "app",
      title: "Applicant Page",
      type: "page",
      private: true,
      company: true,
      roles: ["zenhire"],
    },
    children: [
      {
        path: "",
        element: <ApplicantProfile />,
        meta: {
          company: true,
          title: "Applicant Profile",
          type: "component",
          private: true,
        },
      },
      {
        path: "application/:applicationId",
        element: <ApplicantApplicationWrapper />,
        redirect: true, // because each time user access this route, it will redirect to the first step
        permissions: ["GET_APPLICATIONS"],
        meta: {
          company: true,
          title: "Application",
          type: "component",
          private: true,
        },
        children: [
          {
            path: "step/:stepId",
            element: <ApplicantApplicationStepPage />,
            permissions: ["GET_APPLICANT_TEST_RESULTS"],
            meta: {
              company: true,
              title: "Step",
              type: "component",
              private: true,
            },
            children: [
              {
                path: "preinterview-test/:testId",
                element: (
                  <ApplicantManualScoringTestPage testType="preinterview" />
                ),
                permissions: [
                  "GET_APPLICANT_TEST_RESULTS",
                  "GET_ZENHIRE_TESTS_RESULTS",
                ],
                meta: {
                  company: true,
                  title: "Application",
                  type: "component",
                  private: true,
                },
              },
              {
                path: "qa-test/:testId",
                element: <ApplicantManualScoringTestPage testType="qa-test" />,
                permissions: [
                  "GET_APPLICANT_TEST_RESULTS",
                  "GET_ZENHIRE_TESTS_RESULTS",
                ],
                meta: {
                  company: true,
                  title: "Application",
                  type: "component",
                  private: true,
                },
              },
              {
                path: ":type/preview",
                element: <ApplicantPreviewFile />,
                meta: {
                  company: true,
                  title: "Application",
                  type: "component",
                  private: true,
                },
              },
              {
                path: "live-interview/scoring",
                element: <ApplicantManualScoringLiveInterview />,
                permissions: [
                  "GET_APPLICANT_TEST_RESULTS",
                  "GET_ZENHIRE_TESTS_RESULTS",
                ],
                meta: {
                  company: true,
                  title: "Application",
                  type: "component",
                  private: true,
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: "/applicant/:id/application/:applicationId/applicant-report-pdf",
    element: <ApplicantReport />,
    meta: {
      company: true,
      layout: "blank",
      title: "Application Report",
      type: "page",
      private: true,
    },
  },
];

export default applicantRoutes;
