// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";

function renderDefaultMessage(type: string) {
  switch (type) {
    case "success":
      return "Success.";

    case "error":
      return "An error has occured. Please try again.";

    default:
      return "";
  }
}

interface alertType {
  toast: { message: string | null; type: string | null };
  system: { message: string | null; type: string | null };
  type: string | null;
}

const initialState: alertType = {
  toast: {
    message: "",
    type: "",
  },
  system: {
    message: "",
    type: "",
  },
  type: "",
};

export const alertSlice = createSlice({
  name: "alert",
  initialState,

  reducers: {
    setToast: (state, action) => {
      state.toast = {
        message: action.payload.message
          ? action.payload.message
          : renderDefaultMessage(action.payload.type),
        type: action.payload.type,
      };
    },
    setSystemAlert: (state, action) => {
      state.system.message = action.payload.message;
      state.system.type = action.payload.type;
    },
    clearAlertReducer: (state) => (state = initialState),
  },
  extraReducers: (builder) => {},
});

export const { clearAlertReducer, setSystemAlert, setToast } =
  alertSlice.actions;

export default alertSlice.reducer;
