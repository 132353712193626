import { useEffect, useRef, useState } from "react";
import ReactCrop, {
  centerCrop,
  type Crop,
  makeAspectCrop,
} from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { MdOutlineCenterFocusStrong } from "react-icons/md";
import { ReactComponent as DiscardIco } from "assets/icons/discard-ico.svg";
import Tooltip from "../Tooltip/Tooltip";

type propsTypes = {
  image: string;
  imageStyle?: string;
  onChange: (e: File) => void;
  aspect?: number; // You need to pass aspect (1, 16 / 9, etc...) if you want to crop selection be centered
  roundedCrop?: boolean;
};

const CropImage = ({
  image,
  onChange,
  aspect,
  roundedCrop,
  imageStyle,
}: propsTypes) => {
  const [crop, setCrop] = useState<Crop>();
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const cropImageRef = useRef<null | HTMLImageElement>(null);

  function getCroppedImage(image: HTMLImageElement, crop: Crop) {
    const imageCanvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    imageCanvas.width = Math.ceil(crop.width * scaleX);
    imageCanvas.height = Math.ceil(crop.height * scaleY);
    const imgCx = imageCanvas.getContext("2d");
    imgCx &&
      imgCx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width * scaleX,
        crop.height * scaleY,
      );
    const reader = new FileReader();
    imageCanvas.toBlob(
      (blob) => {
        if (blob) {
          reader.readAsDataURL(blob);
          reader.onloadend = () => {
            if (typeof reader.result === "string") {
              dataURLtoFile(reader.result, `${Date.now()}-cropped.jpg`);
            }
          };
        }
      },
      "image/png",
      1,
    );
  }

  function onCropComplete(crop: Crop) {
    if (cropImageRef.current && crop.width && crop.height) {
      getCroppedImage(cropImageRef.current, crop);
    }
  }

  function dataURLtoFile(dataurl: string, filename: string) {
    const arr: string[] = dataurl.split(",");

    if (arr.length) {
      const mime = arr[0].match(/:(.*?);/);
      let mimeType: string = "";
      if (mime) {
        if (mime.length >= 1) {
          mimeType = mime[1];
        }
      }
      let bstr = atob(arr[1]);
      let n = bstr.length;
      let u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      let croppedImage = new File([u8arr], filename, { type: mimeType });

      onChange(croppedImage);
    }
  }

  function centerCropSelection() {
    if (isImageLoaded && cropImageRef.current && aspect) {
      const width = cropImageRef.current.clientWidth;
      const height = cropImageRef.current.clientHeight;

      const customCrop = centerCrop(
        makeAspectCrop(
          {
            unit: "%",
            width: 100,
          },
          aspect,
          width,
          height,
        ),
        width,
        height,
      );

      setCrop(customCrop);
    }
  }

  useEffect(() => {
    centerCropSelection();
  }, [isImageLoaded]);

  return (
    <div>
      <ReactCrop
        crop={crop}
        onChange={(c) => setCrop(c)}
        aspect={aspect ? aspect : undefined}
        onComplete={onCropComplete}
        keepSelection={true}
        circularCrop={roundedCrop ? true : false}
        minWidth={30}
        minHeight={30}
      >
        <img
          src={image}
          ref={cropImageRef}
          alt={`${image}`}
          className={imageStyle}
          onLoad={() => setIsImageLoaded(true)}
        />
      </ReactCrop>
      <div className="absolute top-[39px] right-[8px]">
        <div className="flex flex-col items-center gap-1">
          <Tooltip
            toggler={
              <button onClick={() => setCrop(undefined)}>
                <DiscardIco className="[&_path]:fill-main-color w-[24px] h-auto" />
              </button>
            }
            content={"Discard Crop Selection"}
            className="border-none text-[13px] -mt-1 !py-1 !px-2.5"
          />
          {aspect && (
            <Tooltip
              toggler={
                <button onClick={centerCropSelection}>
                  <MdOutlineCenterFocusStrong className="text-main-color text-[26px]" />
                </button>
              }
              content={"Center Crop Selection"}
              className="border-none text-[13px] -mt-1 !py-1 !px-2.5"
            />
          )}
          {/* <button>Center</button> */}
        </div>
      </div>
    </div>
  );
};

export default CropImage;
