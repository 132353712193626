import { isRejectedWithValue } from "@reduxjs/toolkit";
import type { MiddlewareAPI, Middleware } from "@reduxjs/toolkit";
import { setGlobalErrors } from "./global";
import { errorType } from "common/types/reducer.types";

// here you can find type of error object that you get from action.payload
// import { errorType } from "common/types/reducer.types";

export const errorMiddleware: Middleware =
  (api: MiddlewareAPI) =>
  (next) =>
  (action: { type: any; payload: errorType }) => {
    if (isRejectedWithValue(action)) {
      if (action.payload.code === "ERR_CANCELED") return;

      action.payload.type && api.dispatch(setGlobalErrors(action.payload));

      // here you can put some if statment and get snack message notification
      // api.dispatch(setToast({ message: action.payload.message }));
    }

    return next(action);
  };
