// ** Redux Imports
import { createAsyncThunk } from "@reduxjs/toolkit";

// ** Axios Imports
import { http } from "common/api/http";
import { setToast } from "redux/alert";
import { RootState } from "redux/store";

interface getTestsParams {
  state?: string;
  page?: number;
  currentLocation?: string;
  companyId?: string;
  paramsArray?: any;
  signal?: any;
}

type multiSelectType = {
  _id: string;
};

export const getTests = createAsyncThunk<
  any,
  getTestsParams,
  { state: RootState }
>(
  "testLibrary/getTests",
  async (
    { state, page, companyId, paramsArray, signal },
    { getState, rejectWithValue },
  ) => {
    try {
      const stateName =
        state === "draft"
          ? "/drafts"
          : state === "incubation"
            ? "/incubator"
            : "";

      const params = {
        limit: 15,
        page: page ? page : 1,
        ...(state && {
          state: state,
        }),
        ...(paramsArray?.filters?.type && {
          automationType: paramsArray?.filters.type,
        }),
        ...(paramsArray?.filters?.template && {
          templateId: paramsArray?.filters.template,
        }),
        ...(paramsArray?.search && {
          search: paramsArray?.search,
        }),
        ...(paramsArray?.filters?.sort && {
          sort: `${paramsArray?.filters?.direction?.value === "desc" ? "-" : ""}${
            paramsArray?.filters.sort.value
          }`,
        }),
        ...(paramsArray?.filters?.microdimensions && {
          microDimIds: `${paramsArray?.filters.microdimensions.map(
            (microdimension: multiSelectType) => microdimension._id,
          )}`,
        }),
        ...(companyId && {
          company: companyId,
        }),
      };

      const response: any = await http.get(`/tests`, { params, signal });

      const values = getState().testLibrary.testLibraryList.values;
      const newValues =
        values && response.data?.page && response.data.page > 1
          ? {
              ...response.data,
              data: [...values.data, ...response.data.data],
            }
          : response.data;
      return newValues;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "CUSTOM",
        style: "MAIN_CONTENT",
      });
    }
  },
);

export const getTestTemplates = createAsyncThunk(
  "testLibrary/getTestTemplates",
  async (_, { rejectWithValue }) => {
    try {
      const response = await http.get("/tests/templates");
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "CUSTOM",
        style: "MAIN_CONTENT",
      });
    }
  },
);

export interface testWizardInput {
  templateId: number;
  name: string;
  icon: string;
  shortDescription: string;
  timerType: string;
  timeLimit?: number;
  timeLimitDemo?: number;
  wpm?: number;
  connectToAi?: boolean;
  statementScaleTestConfig?: {
    options: { text: string }[];
  };
}

export const createTest = createAsyncThunk(
  "testLibrary/createTest",
  async (
    {
      templateId,
      name,
      icon,
      shortDescription,
      timerType = "per_question",
      timeLimit = 0,
      timeLimitDemo = 30,
      wpm = 0,
      connectToAi = false,
      statementScaleTestConfig = {
        options: [],
      },
    }: testWizardInput,
    { dispatch, rejectWithValue },
  ) => {
    try {
      const response = await http.post("/tests", {
        templateId,
        name,
        shortDescription,
        icon,
        timerType,
        timeLimit,
        timeLimitDemo,
        wpm,
        connectToAi,
        ...(statementScaleTestConfig.options.length && {
          statementScaleTestConfig,
        }),
      });

      dispatch(
        setToast({
          message: "Successfully created a test.",
          type: "success",
        }),
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "FORM",
        style: "INFO_BOX",
      });
    }
  },
);

interface getTestsModuleParams {
  positionId: number;
  hiringFlowId: number;
  stepId: number;
  paramsArray?: any;
  companyId?: string;
}

export const getTestsForModule = createAsyncThunk<
  any,
  getTestsModuleParams,
  { state: RootState }
>(
  "testLibrary/getTestsForModule",
  async (
    { positionId, hiringFlowId, stepId, companyId, paramsArray },
    { rejectWithValue },
  ) => {
    const params = {
      ...(paramsArray?.filters?.type && {
        automationType: paramsArray?.filters?.type.value,
      }),
      ...(paramsArray?.filters?.template && {
        templateId: paramsArray?.filters?.template.value,
      }),
      ...(paramsArray?.search && {
        search: paramsArray.search,
      }),
      ...(paramsArray?.filters?.microdimensions && {
        microdimensions: `${paramsArray?.filters?.microdimensions.map(
          (microdimension: multiSelectType) => microdimension._id,
        )}`,
      }),
      ...(paramsArray?.filters?.sort && {
        sort: `${
          paramsArray?.filters?.direction?.value === "desc" ? "-" : ""
        }${paramsArray?.filters?.sort.value}`,
      }),
      ...(companyId && {
        company: companyId,
      }),
    };

    try {
      const response = await http.get(
        `/positions/${positionId}/hiring-flows/${hiringFlowId}/step/${stepId}/tests`,
        { params },
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "ACTION",
        style: "TOAST",
      });
    }
  },
);
