import { useEffect, type ReactNode } from "react";
import { useAppDispatch, useAppSelector } from "redux/reduxTypes";
import { pushLoadedBreadcrumb } from "router/reducer/navigation";

interface routeMiddlewareType {
  route: string;
  children: ReactNode;
}

const NavigationMiddleware = ({ route, children }: routeMiddlewareType) => {
  const dispatch = useAppDispatch();
  const breadcrumbs = useAppSelector(
    (state) => state.navigation.tree.breadcrumbs
  );

  // this use effect keeps track of a loaded components
  useEffect(() => {
    if (route && breadcrumbs.some((b, i) => b.pattern === route)) {
      let updateBreadcrumb = breadcrumbs.find((b) => b.pattern === route);
      dispatch(pushLoadedBreadcrumb(updateBreadcrumb));
    }
  }, [route]);

  return <>{children}</>;
};

export default NavigationMiddleware;
