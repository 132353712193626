// ** UseJWT import to get config

import { http } from "common/api/http";
import { publicHttp } from "common/api/public-http";

export const fetchMe = async () => await http.get("/users/me");

export const verifyEmail = async ({
  verificationToken,
  email,
}: {
  verificationToken: string;
  email: string;
}) =>
  await publicHttp.post("/auth/verify-email", {
    verificationToken,
    email,
  });
