import ExampleTheme from "./themes/ExampleTheme";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import ToolbarPlugin from "./plugins/ToolbarPlugin/ToolbarPlugin.js";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { ListItemNode, ListNode } from "@lexical/list";
import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import CodeHighlightPlugin from "./plugins/CodeHighlightPlugin";
import "./styles/index.css";
import { EditorState } from "lexical";
import ImagesPlugin from "./plugins/ImagePlugin/ImagePlugin";
import { ImageNode } from "./nodes/ImageNode";
import { useMemo, useState } from "react";
import UpdateStatePlugin from "./plugins/UpdateStatePlugin/UpdateStatePlugin";
import { emptyTextEditorState } from "./sampleText";
import { getRandomId, isStringEmpty } from "common/utility/Utils";
import EmptyTextState from "../EmptyTextState/EmptyTextState";
import { findQuestionTextEditorField } from "common/helpers/functions.helpers";

export function checkTextEditorContent(state: string): {
  hasImage: boolean;
  hasText: boolean;
} {
  try {
    const obj = JSON.parse(state);
    const hasText = !!findQuestionTextEditorField(obj.root, "text")[0]?.trim()
      ?.length;
    const hasImage = !!findQuestionTextEditorField(obj.root, "src")[0]?.length;
    return {
      hasText: hasText,
      hasImage: hasImage,
    } as { hasImage: boolean; hasText: boolean };
  } catch (err) {
    return {
      hasText: false,
      hasImage: false,
    };
  }
}

const editorConfig = {
  theme: ExampleTheme,
  namespace: "editor",
  // Handling of errors during update
  onError(error: any) {
    throw error;
  },
  // Any custom nodes go here
  nodes: [
    HeadingNode,
    ListNode,
    ListItemNode,
    QuoteNode,
    CodeNode,
    CodeHighlightNode,
    TableNode,
    TableCellNode,
    TableRowNode,
    AutoLinkNode,
    ImageNode,
    LinkNode,
  ],
};

interface propTypes {
  value?: string;
  label?: string;
  invalid?: boolean;
  invalidMessage?: string;
  className?: string;
  disableCopy?: boolean;
  readOnly?: boolean;
  previewMode?: boolean;
  showPlaceholder?: boolean;
  onChange?: (
    value: string,
    options?: { hasImage?: boolean; hasText?: boolean }
  ) => void;
  hidePlugin?: boolean;
  customPlaceholder?: string;
  htmlValue?: boolean;
  jobDescription?: boolean;
}

export default function TextEditor({
  value,
  label,
  invalid,
  invalidMessage,
  disableCopy,
  readOnly = false,
  showPlaceholder,
  onChange,
  hidePlugin = false,
  customPlaceholder,
  htmlValue,
  jobDescription,
}: propTypes) {
  const id = getRandomId();
  const [hasChanges, setHasChanges] = useState(false);

  const initialState = useMemo(() => {
    if (htmlValue) {
      return value;
    } else {
      try {
        if (typeof value === "string" && JSON.parse(value)) {
          return value;
        }
      } catch (err) {
        return emptyTextEditorState;
      }
      return emptyTextEditorState;
    }
  }, [value]);

  function Placeholder() {
    return (
      <div className="editor-placeholder">
        {customPlaceholder ? customPlaceholder : "You can format text here..."}
      </div>
    );
  }

  function handleChange(editorState: EditorState) {
    if (!readOnly) {
      const jsonString = JSON.stringify(editorState);
      const content = checkTextEditorContent(jsonString);
      let change = hasChanges;
      if (jsonString !== initialState) {
        change = true;
        setHasChanges(true);
      }

      if (change) {
        onChange &&
          onChange(jsonString, {
            hasImage: content.hasImage,
            hasText: content.hasText,
          });
      }
    }
  }

  const checkIsThereText = () => {
    if (value) {
      if (
        isStringEmpty(value) ||
        value === emptyTextEditorState ||
        (!checkTextEditorContent(value).hasImage &&
          !checkTextEditorContent(value).hasText)
      ) {
        return false;
      } else {
        return true;
      }
    }
  };

  return (
    <>
      {label ? (
        <label
          // htmlFor="textarea-input"
          className={"text-dark-gray text-sm font-light mb-1.5"}
        >
          {label}
        </label>
      ) : (
        ""
      )}
      <div className={`relative ${invalid && "invalid-editor-style"} w-full`}>
        <LexicalComposer
          initialConfig={{
            ...editorConfig,
            editable: !readOnly,
            editorState: !htmlValue ? initialState : undefined,
          }}
        >
          <div
            className={`editor-container overflow-hidden w-full z-9 ${
              readOnly ? "read-only" : ""
            }   ${invalid ? "border border-invalid-red" : "border"}`}
          >
            {readOnly || hidePlugin ? "" : <ToolbarPlugin id={id} />}
            <div className={`${readOnly ? "select-none" : "editor-inner"}`}>
              <RichTextPlugin
                contentEditable={
                  <ContentEditable
                    onCopy={(event) =>
                      disableCopy && readOnly ? event.preventDefault() : null
                    }
                    className="editor-input focus-visible:outline-none"
                  />
                }
                placeholder={!readOnly ? <Placeholder /> : <></>}
                ErrorBoundary={LexicalErrorBoundary}
              />
              <AutoFocusPlugin />
              <ListPlugin />
              <LinkPlugin />
              <ImagesPlugin />
              {!readOnly && (
                <OnChangePlugin
                  onChange={handleChange}
                  ignoreSelectionChange={true}
                />
              )}
              {
                <UpdateStatePlugin
                  value={initialState}
                  jobDescription={jobDescription}
                />
              }
              {/* <MarkdownShortcutPlugin transformers={TRANSFORMERS} /> */}
              <CodeHighlightPlugin />
            </div>
            {/* <ActionsPlugin /> */}
          </div>
        </LexicalComposer>
        <div id={`text-editor-root-${id}`}></div>
        {!showPlaceholder
          ? ""
          : !checkIsThereText() && <EmptyTextState className="w-full" />}
        {invalidMessage && invalid && (
          <p className="text-sm text-invalid-red font-normal">
            {invalidMessage}
          </p>
        )}
      </div>
    </>
  );
}
