import Select from "react-select";
import { useEffect, useRef, useState } from "react";
import { ReactComponent as CloseIco } from "assets/icons/close-ico.svg";

import { ReactComponent as SearchIco } from "assets/icons/search-ico.svg";

type propsTypes = {
  data: {
    _id: string;
    value: string;
    label: string;
    isDisabled?: boolean;
  }[];
  selectedData?: {
    _id?: string;
    value: string;
    label: string;
    isDisabled?: boolean;
  }[];
  onChange: (selectedValue: any) => void;
  placeholder?: string;
  disabled?: boolean;
};

const MultipleFiltersSelect = (props: propsTypes) => {
  const [options, setOptions] = useState(props.data);
  useEffect(() => {
    setOptions(props.data);
  }, [props.data]);

  const handleSelect = (value: any) => {
    props.onChange(value);
  };

  const CustomIndicator = (props: any) => {
    return (
      <div className="custom-indicator ml-[3px]">
        <SearchIco />
      </div>
    );
  };

  const handleRemoveValue = (valueName: any) => {
    const removedValue = props?.selectedData?.filter(
      (val: any) => val.value !== valueName,
    );
    if (!removedValue) return;
    handleSelect(removedValue);
  };

  return (
    <div>
      <Select
        options={options}
        value={props.selectedData}
        onChange={(value: any) => handleSelect(value)}
        isMulti
        controlShouldRenderValue={false}
        components={{ DropdownIndicator: CustomIndicator }}
        placeholder={`${props.placeholder}` || "Search"}
        menuPosition={"fixed"}
        isClearable={false}
        isDisabled={props?.disabled}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            display: "flex",
            borderRadius: 10,
            borderColor: "#BBBBBB",
            "&:hover": "transparent",
            boxShadow: "none",
            height: "35px",
            "::-webkit-scrollbar": {
              width: "1px",
              height: "0px",
            },
            "::-webkit-scrollbar-track": {
              background: "#f1f1f1",
            },
            "::-webkit-scrollbar-thumb": {
              background: "#aaa",
            },
            "::-webkit-scrollbar-thumb:hover": {
              background: "#aaa",
            },
          }),
          container: (baseStyles) => ({
            ...baseStyles,
            borderColor: "#1A1A1A",
          }),
          valueContainer: (baseStyles) => ({
            ...baseStyles,
            order: 2,
            paddingLeft: "0px",
          }),
          placeholder: (baseStyles) => ({
            ...baseStyles,
            color: "#838383",
            fontSize: "13px",
            marginTop: "-1px",
          }),
          indicatorSeparator: (baseStyles) => ({
            ...baseStyles,
            display: "none",
          }),
          menu: (baseStyles) => ({
            ...baseStyles,
            borderRadius: 10,
            padding: 5,
            backgroundColor: "#1A1A1A",
          }),
          option: (baseStyles, state) => ({
            ...baseStyles,
            borderRadius: 10,
            cursor: state.isDisabled ? "unset" : "pointer",
            backgroundColor: state.isFocused
              ? "#3B3B3B"
              : state.isDisabled
                ? "#868686"
                : "#1A1A1A",
            color: "white",
          }),
          menuList: (base) => ({
            ...base,

            "::-webkit-scrollbar": {
              width: "1px",
              height: "0px",
            },
            "::-webkit-scrollbar-track": {
              background: "#f1f1f1",
            },
            "::-webkit-scrollbar-thumb": {
              background: "#aaa",
            },
            "::-webkit-scrollbar-thumb:hover": {
              background: "#aaa",
            },
          }),
        }}
      />
      <div className="mt-1 flex flex-wrap gap-1">
        {props?.selectedData?.length
          ? props?.selectedData?.map((val: any) => (
              <div
                key={val.value}
                className="text-[12px] rounded-[5px] bg-main-color p-1 text-white flex items-center gap-2"
              >
                {val.label}
                <div
                  className={
                    "rounded-full bg-white w-[15px] h-[15px] flex justify-center items-center p-[2px] cursor-pointer"
                  }
                >
                  <CloseIco
                    onClick={() => handleRemoveValue(val.value)}
                    className={"w-full [&_path]:fill-main-color mt-[2px]"}
                  />
                </div>
              </div>
            ))
          : null}
      </div>
    </div>
  );
};

export default MultipleFiltersSelect;
