import {
  FunctionComponent,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import Button from "../Button/Button";
import { Link } from "react-router-dom";
import { ReactComponent as PlusIco } from "assets/icons/plus-ico.svg";
import SearchInput from "./components/SearchInput";
import ScrollBar from "react-perfect-scrollbar";
import { ReactComponent as SideBarIco } from "assets/icons/sidebar-ico.svg";
import { ReactComponent as DropdownDots } from "assets/icons/dropdown-dots-icon.svg";
import "./filters-side-bar.style.css";
import CustomDropdown from "../CustomDropdown/CustomDropdown";

type typeProps = {
  sidebar: {
    sidebarClassName?: string;
    children: ReactNode;
    hideSidebar?: boolean;
    dropdownOptions?: {
      hide?: boolean;
      options: any;
    };
  };
  contentClassName?: string;
  children: ReactNode;
  title: {
    text: string;
    Icon?: React.FunctionComponent<
      React.SVGProps<SVGSVGElement> & { title?: string | undefined }
    >;
    children?: ReactNode;
  };
  createButton?: {
    text: string;
    hide?: boolean;
    link: string;
  };
  searchBar?: {
    placeholder?: string;
  };
};
const FiltersSideBar: FunctionComponent<typeProps> = (props) => {
  const {
    sidebar,
    contentClassName,
    children,
    title,
    createButton,
    searchBar,
  } = props;

  const [scrollBarHeight, setScrollBarHeight] = useState<number | undefined>(
    undefined,
  );

  const sidebarRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (sidebarRef.current && contentRef.current) {
      const sidebarHeight = sidebarRef.current.clientHeight;
      const contentHeight = contentRef.current.clientHeight;
      setScrollBarHeight(sidebarHeight - contentHeight);
    }
  }, [sidebarRef.current, contentRef.current]);

  const [isVisible, setIsVisible] = useState(true);
  const toggleSidebar = () => {
    setIsVisible((prevState) => !prevState);
  };

  const [divWidth, setDivWidth] = useState<number>(0);

  useEffect(() => {
    if (sidebarRef.current) {
      setDivWidth(sidebarRef.current.clientWidth);
    }

    // Optional: Add an event listener for window resize to update width dynamically
    const handleResize = () => {
      if (sidebarRef.current) {
        setDivWidth(sidebarRef.current.clientWidth);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="relative flex-1 overflow-hidden flex mx-auto w-full">
      {!sidebar?.hideSidebar ? (
        <div
          style={{
            marginLeft: !isVisible ? `-${divWidth}px` : "",
          }}
          ref={sidebarRef}
          className={`${
            sidebar?.sidebarClassName
              ? sidebar?.sidebarClassName
              : "transition-all relative min-w-[257px] max-w-[257px] zlg:min-w-[230px] zlg:max-w-[230px] flex flex-col h-auto pt-[20px] border-r border-z-grey-200"
          }`}
        >
          <div
            className={"px-[15px] zlg:px-[10px] flex flex-col gap-5"}
            ref={contentRef}
          >
            <div
              className={
                "flex items-center justify-between gap-2.5 cursor-default"
              }
            >
              <div
                className={`flex gap-2.5 items-center ${sidebar.dropdownOptions && !sidebar.dropdownOptions.hide ? "max-w-[140px]" : "max-w-[190px]"}`}
              >
                {title.Icon ? (
                  <title.Icon className={"[&_path]:fill-main-color"} />
                ) : null}
                {title.children ? title.children : null}
                <span className={"text-xl font-[600] text-z-grey-650 truncate"}>
                  {title.text}
                </span>
              </div>
              <div className={"mt-[2px] flex items-center justify-center"}>
                {sidebar.dropdownOptions && !sidebar.dropdownOptions.hide ? (
                  <CustomDropdown
                    IconDropdown={DropdownDots}
                    functionOption={sidebar.dropdownOptions.options}
                    fillIcon={
                      "[&_path]:fill-[#C6C6C6] group-hover:[&_path]:fill-main-color"
                    }
                  />
                ) : null}
                <div onClick={toggleSidebar} className={"cursor-pointer"}>
                  <SideBarIco className={""} />
                </div>
              </div>
            </div>
            {createButton && !createButton.hide ? (
              <Link to={createButton.link}>
                <Button
                  type="Primary"
                  text={createButton.text}
                  FunctionIcon={PlusIco}
                  className="!rounded-[5px] !w-full !px-[7px] !py-[5.5px] !text-sm"
                />
              </Link>
            ) : null}
            {searchBar ? (
              <SearchInput placeholder={searchBar?.placeholder} />
            ) : null}
            <hr className="border-1 border-z-grey-200" />
          </div>
          <ScrollBar
            component="div"
            options={{
              wheelPropagation: true,
            }}
            className={
              "px-[15px] zlg:px-[10px] flex flex-col gap-5 pb-[20px] pt-[10px]"
            }
            style={{
              height: `${scrollBarHeight}px`,
            }}
          >
            {sidebar?.children}
          </ScrollBar>
        </div>
      ) : null}
      {!isVisible ? (
        <div
          className={
            "transition-all relative w-[40px] flex flex-col h-auto pt-[20px] border-r border-z-grey-200"
          }
        >
          <div
            onClick={toggleSidebar}
            className={"cursor-pointer flex justify-center items-center"}
          >
            <SideBarIco className={""} />
          </div>
        </div>
      ) : null}
      <div
        id="sideBarPageDiv"
        className={`w-full flex flex-col overflow-auto show-animation ${
          contentClassName ? contentClassName : ""
        }`}
      >
        {children}
      </div>
    </div>
  );
};

export default FiltersSideBar;
