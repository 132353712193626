import { useAppSelector } from "../../redux/reduxTypes";
export const useAuthorization = () => {
  const permissionsAuth = useAppSelector(
    (state) => state.authentication?.userRole?.permissions
  );
  const permissionsRole = useAppSelector((state) =>
    state.authentication?.userRole?.role?.toLowerCase()
  );
  const checkPermissions = (permissionsCheck: string | string[]): boolean => {
    if (Array.isArray(permissionsCheck)) {
      let state: boolean = false;
      permissionsCheck.forEach((item) => {
        if (permissionsAuth?.includes(item)) state = true;
      });
      return state;
    } else {
      return !!permissionsAuth?.includes(permissionsCheck);
    }
  };

  const allPermissionsCheck = (permissionsCheck: string[]): boolean => {
    return permissionsCheck.every((item) => permissionsAuth?.includes(item));
  };

  const checkRoles = (rolesCheck: string | string[]): boolean => {
    if (Array.isArray(rolesCheck)) {
      let state: boolean = false;
      rolesCheck.forEach((item) => {
        if (permissionsRole?.includes(item)) state = true;
      });
      return state;
    } else {
      return !!permissionsRole?.includes(rolesCheck);
    }
    return false;
  };

  return { checkPermissions, checkRoles, allPermissionsCheck };
};
