import MergeLayoutRoutes from "./MergeLayoutRoutes";

// ** Routes Imports
import authRoutes from "views/features/auth/routes/index";
import dashboardRoutes from "views/features/dashboard/routes";
import exceptionRoutes from "views/features/exception/routes";
import testLibraryRoutes from "views/features/testLibrary/routes";
import microdimensionsRoutes from "views/features/microdimensions/routes";
import usersRoutes from "views/features/users/routes";
import applicantRoutes from "views/features/applicant/routes";
import applicantsRoutes from "views/features/applicants/routes";
import applicationRoutes from "views/features/application/routes";
import positionsRoutes from "views/features/positions/routes";
import positionRoutes from "views/features/position/routes";
import companiesRoute from "../../views/features/companies/routes";
import companyAuthRoutes from "../../views/features/company/companyAuth/routes";
import companyDashboardRoutes from "../../views/features/company/companyDashboard/routes";
import systemEmails from "views/features/systemEmails/routes";
import { filterCompanyRoutes } from "router/helpers/router.functions";

// import all admin routes
const adminRoutes = [
  ...authRoutes,
  ...exceptionRoutes,
  ...dashboardRoutes,
  ...testLibraryRoutes,
  ...microdimensionsRoutes,
  ...usersRoutes,
  ...applicantRoutes,
  ...applicantsRoutes,
  ...applicationRoutes,
  ...positionsRoutes,
  ...companiesRoute,
  ...systemEmails,
];

// import all company only routes
const companyOnlyRoutes = [
  ...companyAuthRoutes,
  ...companyDashboardRoutes,
  ...positionRoutes,
];

// prepare all company routes
const companyRoutes = [
  ...filterCompanyRoutes([...adminRoutes, ...companyOnlyRoutes]),
];

// all routes
const routes = [...adminRoutes, ...companyRoutes];

const getRoutes = () => {
  return [
    // here are all routes available in the app
    ...MergeLayoutRoutes(routes),
  ];
};

export { routes, getRoutes };
