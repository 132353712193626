// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";
import { errorType } from "common/types/reducer.types";
import { getBrowserName } from "common/utility/Utils";

interface alertType {
  isMobile: boolean;
  scrollDirection: "UP" | "DOWN" | null;
  browser: { value: string; human: string };
  errors: {
    customError: errorType | null;
    filtersError: errorType | null;
    formError: errorType | null;
    actionError: errorType | null;
  };
}

const initialState: alertType = {
  isMobile: false,
  scrollDirection: null,
  browser: getBrowserName() || { value: "", human: "" },
  errors: {
    customError: null,
    filtersError: null,
    formError: null,
    actionError: null,
  },
};

export const globalSlice = createSlice({
  name: "global",
  initialState,

  reducers: {
    setIsMobile: (state, { payload }) => {
      state.isMobile = payload;
    },
    setScrollDirection: (state, { payload }) => {
      state.scrollDirection = payload;
    },
    setGlobalErrors: (state, { payload }: { payload: errorType }) => {
      switch (payload.type) {
        case "ACTION":
          state.errors.actionError = payload;
          break;

        case "FILTERS":
          state.errors.filtersError = payload;
          break;

        case "FORM":
          state.errors.formError = payload;
          break;

        case "CUSTOM":
          state.errors.customError = payload;
          break;

        default:
          state.errors = initialState.errors;
      }
    },
    clearGlobalErrors: (state) => {
      state.errors = initialState.errors;
    },
  },
  extraReducers: (builder) => {},
});

export const {
  setIsMobile,
  setScrollDirection,
  setGlobalErrors,
  clearGlobalErrors,
} = globalSlice.actions;

export default globalSlice.reducer;
