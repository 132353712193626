import { useEffect, useMemo, useState } from "react";
import { ReactComponent as NoImageIco } from "assets/icons/no-image.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";

interface propTypes {
  src: string;
  alt: string;
  className?: string;
  style?: Object;
  lazy?: boolean;
  noImageClass?: string;
  hideNoImage?: boolean;
}

function formatSrc(src: string) {
  return src.includes("http://") && !src.includes("blob")
    ? src.replace("http://", "https://")
    : src;
}

export const ImagePreview = ({
  src,
  alt,
  className,
  style,
  lazy,
  noImageClass,
  hideNoImage,
}: propTypes) => {
  const [loadingImg, setLoadingImg] = useState<boolean>(true);
  const [file, setFile] = useState<string>(formatSrc(src));
  const [error, setError] = useState<boolean>(false);

  function start() {
    setLoadingImg(false);
    setError(false);
  }
  function onError() {
    setLoadingImg(false);
    setError(true);
  }

  const Tag = useMemo(() => {
    if (lazy) {
      return LazyLoadImage;
    }
    return "img";
  }, [lazy]);

  useEffect(() => {
    if (src) {
      setFile(formatSrc(src));
    }
  }, [src]);

  return !hideNoImage ? (
    <>
      {loadingImg ? (
        <div
          className={`animate-pulse flex space-x-4 bg-[#F1F1F1] border border-white ${
            className ? className : ""
          } ${noImageClass ? noImageClass : ""}`}
          style={style}
        ></div>
      ) : (
        ""
      )}

      {error || !src ? (
        <NoImageIco
          className={`${
            className ? className : ""
          } ${noImageClass ? noImageClass : ""}`}
        />
      ) : (
        <Tag
          src={file}
          alt={alt}
          onLoad={start}
          onError={onError}
          className={`${loadingImg ? "hidden" : ""} ${
            error
              ? noImageClass
                ? noImageClass
                : ""
              : className
                ? className
                : ""
          } `}
          style={style}
        />
      )}
    </>
  ) : (
    <></>
  );
};
