import useHorizontalScroll from "common/hooks/useHorizontalScroll";
import { ReactNode, useEffect, useRef, useState } from "react";
import ScrollBar from "views/components/ZenPerfectScrollbar/src/index";

interface propTypes {
  children: ReactNode;
  id: string;
  className?: string;
}

const ScrollBarWrapperX = ({ children, id, className }: propTypes) => {
  useHorizontalScroll(`.js-scroll-${id}`);

  const focusRef: any = useRef(null);
  const [currentScrollState, setCurrentScrollState] = useState<
    "init" | "start" | "end" | "scrolling"
  >("init");
  const [scrollBarPropagation, setScrollBarPropagation] = useState(true);

  useEffect(() => {
    if (currentScrollState === "scrolling" || currentScrollState === "init") {
      setScrollBarPropagation(false);
    } else {
      setScrollBarPropagation(true);
    }
  }, [currentScrollState]);

  return (
    <>
      <div id={`focus-${id}`} ref={focusRef}></div>
      <ScrollBar
        component="div"
        shouldRefresh={true}
        className={`${`js-scroll-${id}`} ${className || ""}`}
        options={{
          wheelPropagation: scrollBarPropagation,
        }}
        onXReachStart={() => {
          if (currentScrollState !== "init") {
            setCurrentScrollState("start");
          }
        }}
        onXReachEnd={() => {
          setCurrentScrollState("end");
        }}
        onScrollX={() => {
          setCurrentScrollState("scrolling");
        }}
        onScrollLeft={() => {
          setCurrentScrollState("scrolling");
        }}
        onScrollRight={() => {
          setCurrentScrollState("scrolling");
        }}
      >
        {children}
      </ScrollBar>
    </>
  );
};

export default ScrollBarWrapperX;
