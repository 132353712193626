export async function stopStreaming(stream: MediaStream | null) {
  if (stream) {
    // check if stream exists
    // then turn off camera and microphone
    const tracks = stream.getTracks();

    tracks.forEach((track) => {
      track.stop();
    });
  }
}
