import { Fragment, ReactNode, useCallback, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { ReactComponent as ImgAnswer } from "assets/icons/img-answer.svg";
import { ReactComponent as FileImage } from "assets/icons/document-big-ico.svg";
import filePreviewIco from "assets/icons/hamburger-ico-white.svg";
import imgIco from "assets/icons/uploaded-img-ico.svg";
import { ReactComponent as DeleteIco } from "assets/icons/discard-ico.svg";
import { getRandomId, isObjEmpty } from "common/utility/Utils";
import CreatePortal from "../CreatePortal/CreatePortal";
import CropImage from "../CropImage/CropImage";
import Button from "../Button/Button";
import Modal from "../Modal/Modal";

const fileMimeTypes = ["pdf", "rar", "zip", "doc", "plain"];
const imageMimeTypes = ["png", "jpeg", "jpg"];
const pdfMimeTypes = ["pdf"];
const csvMimeType = ["csv"];
const excelMimeType = ["csv", "xls", "xlsx"];
const textMimeType = ["doc", "docx", "txt"];

function getSupportedFileType(values: string[]) {
  let tempArr: string[] = [];
  for (let i = 0; i < values.length; i++) {
    switch (values[i]) {
      case "image":
        tempArr = tempArr.concat(imageMimeTypes);
        break;
      case "file":
        tempArr = tempArr.concat(fileMimeTypes);
        break;
      case "pdf":
        tempArr = tempArr.concat(pdfMimeTypes);
        break;
      case "csv":
        tempArr = tempArr.concat(csvMimeType);
        break;
      case "excel":
        tempArr = tempArr.concat(excelMimeType);
        break;
      case "text":
        tempArr = tempArr.concat(textMimeType);
        break;
      default:
        break;
    }
  }
  return tempArr;
}

function renderSupportedMimeTypes(supported: string[]) {
  return supported.map((v: string, i: number) => {
    return (
      <Fragment key={i}>
        {v}
        {i === supported.length - 1 ? "" : ", "}
      </Fragment>
    );
  });
}

function switchFileTypes(value: string) {
  switch (value) {
    case "image":
      return {
        "image/*": [".png", ".gif", ".jpeg", ".jpg"],
      };
    case "file":
      return {
        "application/pdf": [],
        "application/zip": [],
        "application/rar": [],
        "application/doc": [],
        "application/plain": [],
      };
    case "pdf":
      return {
        "application/pdf": [".pdf"],
      };
    case "csv":
      return {
        "text/*": [".csv"],
      };
    case "excel":
      return {
        "text/*": [".csv", ".xls", ".xlsx"],
      };
    case "text":
      return {
        "text/*": [".doc", ".docx"],
      };
    default:
      return {};
  }
}

function checkFilesSize(files: File[]) {
  let size = 0;
  for (let i = 0; i < files.length; i++) {
    size = size + files[i].size;
  }
  return size;
}

interface propTypes {
  accept?: string[];
  onChange?: (file: any) => void;
  type?: "multiple" | "single";
  onRemove?: (file?: any) => void;
  preview?: boolean;
  invalid?: boolean;
  placeholder?: string;
  hideSupportType?: boolean;
  hideIconUpload?: boolean;
  crop?: boolean;
  cropAspect?: number;
  maxFiles?: number;
  hideMaxFileSize?: boolean;
  maxFileSize?: number;
}

const UploadDropzone = ({
  accept = ["image"],
  onChange,
  onRemove,
  preview,
  invalid,
  placeholder,
  hideSupportType,
  hideIconUpload,
  crop,
  cropAspect,
  maxFiles,
  hideMaxFileSize,
  type,
  maxFileSize,
}: propTypes) => {
  const [myFiles, setMyFiles] = useState<File[]>([]);
  const [originalFile, setOriginalFile] = useState<File[]>([]);
  const [error, setError] = useState(false);
  const [errorSize, setErrorSize] = useState(false);
  const [openCropModal, setOpenCropModal] = useState(false);
  const [croppedImage, setCroppedImage] = useState<File>();

  const acceptFormat = useMemo(() => {
    if (accept?.length) {
      let tempObj = {};
      for (let i = 0; i < accept.length; i++) {
        tempObj = {
          ...tempObj,
          ...switchFileTypes(accept[i]),
        };
      }
      if (isObjEmpty(tempObj)) {
        return {
          "image/*": [".png", ".gif", ".jpeg", ".jpg"],
        };
      }
      return tempObj;
    }
    return {
      "image/*": [".png", ".gif", ".jpeg", ".jpg"],
    };
  }, [accept]);

  const initialPlaceholder: ReactNode = useMemo(() => {
    const supported = getSupportedFileType(accept);
    return (
      <div
        className={`flex flex-col items-center justify-center ${
          hideIconUpload || hideSupportType ? "p-0" : "p-12"
        } text-center`}
      >
        {hideIconUpload ? null : (
          <Fragment>
            {accept.includes("image") ? (
              <ImgAnswer className="w-[50px] h-[50px] [&_path]:fill-main-color" />
            ) : (
              <FileImage className="w-[50px] h-[50px] [&_path]:fill-main-color" />
            )}
          </Fragment>
        )}
        <p className="text-lg font-light text-gray mt-3">
          {placeholder
            ? placeholder
            : `Drop your ${
                accept.includes("image") ? "image" : "file"
              } here or${" "}`}
          <span className="font-medium text-main-color">browse</span>
        </p>
        {hideSupportType ? null : (
          <Fragment>
            <p className="text-sm font-light text-gray">
              Supports: {renderSupportedMimeTypes(supported)}
            </p>
            {maxFiles && (
              <p className="text-sm font-light text-gray">
                Max number of files: {maxFiles}
              </p>
            )}
            {!hideMaxFileSize ? (
              <p className="text-sm font-light text-gray">
                Max file size: {maxFileSize ? maxFileSize : 10}MB
              </p>
            ) : null}
          </Fragment>
        )}
      </div>
    );
  }, [accept]);

  const errorPlaceholder: ReactNode = useMemo(() => {
    const supported = getSupportedFileType(accept);

    return (
      <div className="flex flex-col gap-1 items-center justify-center p-4 text-center">
        <p className="text-lg font-light text-gray">
          File rejected. Click to try again
        </p>
        <p className="text-lg font-light text-timer-red mt-3">
          Please, upload only {maxFiles ? maxFiles : "1"} file
        </p>
        <p className="text-sm font-light text-timer-red">
          Supports: {renderSupportedMimeTypes(supported)}
        </p>
        {!hideMaxFileSize ? (
          <p className="text-sm font-light text-timer-red">
            Max file size: {maxFileSize ? maxFileSize : 10}MB
          </p>
        ) : null}
      </div>
    );
  }, [accept]);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      acceptedFiles.forEach((file) => {
        if (type === "multiple") {
          setMyFiles([...myFiles, ...acceptedFiles]);
        } else {
          setMyFiles([...acceptedFiles]);
        }
        crop && setOriginalFile([...acceptedFiles]);
        crop && setOpenCropModal(true);
        // setPlaceholder(initialPlaceholder);
        setError(false);
        if (onChange) {
          if (type === "multiple") {
            onChange([...acceptedFiles]);
          } else {
            onChange([...acceptedFiles][0]);
          }
        }
      });
    },
    [myFiles],
  );

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: maxFiles ? maxFiles : 1,
    accept: {
      ...acceptFormat,
      // "application/pdf",
      // "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      // "application/msword",
      // "application/vnd.ms-excel",
      // "application/vnd.ms-powerpoint",
      // "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      // "text/plain",
      // "application/vnd.rar",
      // "application/zip",
      // "image/svg+xml",
    },
    onDrop: (acceptedFiles: File[]) => {
      let err = false;

      const MAX_FILE_SIZE = (maxFileSize || 10) * 1024 * 1024; // 4MB in bytes
      acceptedFiles.forEach((file) => {
        if (file.size > MAX_FILE_SIZE) {
          err = true;
        }
      });

      if (checkFilesSize(acceptedFiles) > 10000000) {
        err = true;
      }
      if (err) {
        // setPlaceholder(errorPlaceholder);
        setError(true);
        return;
      } else {
        // setPlaceholder(errorPlaceholder);
        setError(true);
        onDrop([...acceptedFiles.map((file) => Object.assign(file))]);
      }
    },
    onDropRejected: () => {
      // setPlaceholder(errorPlaceholder);
      setError(true);
    },
  });

  const removeFile = (file: File) => () => {
    const newFiles = [...myFiles];
    newFiles.splice(newFiles.indexOf(file), 1);
    setMyFiles(newFiles);
    crop && setCroppedImage(undefined);
    crop && setOriginalFile([]);
    if (onRemove) {
      if (type === "multiple") {
        onRemove(newFiles);
      } else {
        onRemove();
      }
    }
  };

  const files = myFiles.map((file: any) => {
    return (
      <p
        key={file.path}
        className="flex items-center gap-3 bg-main-color rounded-xl py-2 px-3 w-fit mt-2"
      >
        <img
          src={file.type.includes("image") ? imgIco : filePreviewIco}
          alt="img-ico"
        />
        <span className="text-white font-light">{file.path}</span>
        <span onClick={removeFile(file)} className="z-10 cursor-pointer">
          <DeleteIco className="[&_path]:fill-white" />
        </span>
      </p>
    );
  });

  const showPreview = myFiles.map((file: any) => {
    const fileData = Object.assign(file, {
      preview: URL.createObjectURL(file),
    });

    return (
      <div
        className="flex flex-col border-2 border-main-color p-0.5 h-auto"
        key={`${fileData.name}-${getRandomId()}`}
      >
        <div className="flex overflow-hidden w-[100px]">
          <img src={fileData.preview} className="w-auto h-full block rounded" />
        </div>
      </div>
    );
  });

  const showCropPreview = (croppedImage: File) => {
    const fileData = Object.assign(croppedImage, {
      preview: URL.createObjectURL(croppedImage),
    });

    return (
      <div className="flex items-start justify-center gap-5">
        <div
          className="flex flex-col border-2 border-main-color p-0.5 w-[380px] h-[200px] rounded-md hover:opacity-80 bg-ultra-light-gray overflow-hidden"
          key={`${fileData.name}-${getRandomId()}`}
        >
          <div className="flex overflow-hidden min-w-[0px] h-[49px]">
            <img
              src={fileData.preview}
              className="w-full h-full object-cover block"
            />
          </div>
          <div className="flex items-start justify-center gap-2 px-8 -mt-2">
            <div className="flex flex-col justify-center w-2/3 gap-2">
              <div className="w-full bg-white rounded-sm h-[60px]"></div>
              <div className="w-full bg-white rounded-sm h-[20px]"></div>
            </div>
            <div className="flex flex-col justify-center w-1/3 gap-2">
              <div className="w-full bg-white rounded-sm h-[90px]"></div>
              <div className="w-full bg-white rounded-sm h-[20px]"></div>
            </div>
          </div>
        </div>
        <div
          className="flex flex-col border-2 border-main-color p-0.5 w-[100px] h-[200px] rounded-md hover:opacity-80 bg-ultra-light-gray overflow-hidden"
          key={`${fileData.name}-${getRandomId()}`}
        >
          <div className="flex overflow-hidden h-[65px]">
            <img
              src={fileData.preview}
              className="w-full h-full object-cover block"
            />
          </div>
          <div className="flex flex-col items-center justify-center gap-4 h-full">
            <div className="w-full bg-white rounded-sm h-[60px]"></div>
            <div className="w-full bg-white rounded-sm h-[20px] mt-auto"></div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {!croppedImage && (
        <section
          className={`relative border-[3px] ${
            invalid
              ? "border-invalid-red bg-invalid-dimmed-red"
              : "border-light-gray"
          } border-dashed p-5 w-full flex flex-col h-full bg-transparent overflow-auto scrollbar-hide`}
        >
          <div {...getRootProps({ className: "dropzone -mt-[10px]" })}>
            <input {...getInputProps()} />
            <div
              className={`absolute top-0 left-0 ${
                invalid ? "bg-invalid-dimmed-red" : "bg-transparent"
              } cursor-pointer w-full h-full`}
            ></div>
            {files.length === 0
              ? error
                ? errorPlaceholder
                : initialPlaceholder
              : ""}
          </div>
          <div className="realtive flex gap-2 items-center flex-wrap">
            {files}
          </div>
        </section>
      )}
      {!crop && preview && (
        <div
          className="flex flex-col gap-2"
          onClick={() => setOpenCropModal(true)}
        >
          {showPreview[0] && (
            <h4 className="font-light text-gray text-sm">File preview</h4>
          )}
          <div className="flex flex-wrap">{showPreview}</div>
        </div>
      )}
      {crop && myFiles[0] && !openCropModal && (
        <div
          className="flex flex-col items-center gap-2 cursor-pointer"
          onClick={() => setOpenCropModal(true)}
        >
          <h4 className="font-light text-gray text-sm">File preview</h4>
          <div className="flex flex-wrap">{showCropPreview(myFiles[0])}</div>
        </div>
      )}
      {crop && openCropModal && (
        <CreatePortal>
          <Modal close={() => null} removePadding={true}>
            <div className="relative flex flex-col items-start px-10 pt-10">
              <div className="w-full">
                <CropImage
                  image={URL.createObjectURL(originalFile[0])}
                  onChange={(e) => {
                    setCroppedImage(e);
                  }}
                  aspect={cropAspect}
                  roundedCrop={false}
                  imageStyle="rounded-md w-[400px] h-auto"
                />
              </div>
              <div className="sticky bottom-0 left-0 flex gap-4 items-center w-full pt-4 pb-5">
                <Button
                  type={"Secondary"}
                  text={"Cancel"}
                  onClick={() => {
                    setOpenCropModal(false);
                  }}
                  className="rounded-xl !w-1/2"
                />
                <Button
                  type={"Primary"}
                  text={"Save"}
                  onClick={() => {
                    if (onChange && croppedImage) {
                      // setUploadedIcon(URL.createObjectURL(croppedImage));
                      setMyFiles([croppedImage]);
                      onChange(croppedImage);
                      // onCropSave && onCropSave();
                    }
                    setOpenCropModal(false);
                  }}
                  className="rounded-xl !w-1/2"
                />
              </div>
            </div>
          </Modal>
        </CreatePortal>
      )}
    </>
  );
};

export default UploadDropzone;
