// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";
import {
  forgotPassword,
  getMe,
  handleLogout,
  loginUser,
  resetPassword,
  updateMe,
} from "./auth.actions";
import { userDataType } from "../auth.types";
import { errorType } from "common/types/reducer.types";

interface authenticationReducerType {
  loggedIn: boolean;
  loggedOut: boolean;
  userData: {
    processing: boolean;
    loading: boolean;
    resetPass: boolean;
    value: userDataType | null;
    isPasswordChangedAfterInitialLogin: boolean;
    error: errorType | any;
  };
  userRole: {
    role: string | null;
    permissions: string[] | null;
    company?: string;
  };
  forgotPassword: {
    processing: boolean;
    loading: boolean;
    error: errorType | any;
  };
  error: errorType | any;
  loading: boolean;
  processing: boolean;
}

const initialState: authenticationReducerType = {
  loggedIn: false,
  loggedOut: false,
  userData: {
    processing: false,
    resetPass: false,
    isPasswordChangedAfterInitialLogin: false,
    loading: false,
    value: null,
    error: null,
  },
  userRole: {
    role: null,
    permissions: null,
  },
  forgotPassword: {
    processing: false,
    loading: false,
    error: null,
  },
  error: null,
  loading: false,
  processing: false,
};

export const authSlice = createSlice({
  name: "authentication",
  initialState: initialState,
  reducers: {
    clearResetPass: (state) => {
      state.userData.resetPass = false;
    },
    clearLoginError: (state) => {
      state.error = initialState.error;
    },
    clearForgotPassword: (state) => {
      state.forgotPassword = initialState.forgotPassword;
    },
    changeAuthPassword: (state) => {
      state.userData.isPasswordChangedAfterInitialLogin = true;
    },
    handleUserPermission: (state, action) => {
      state.userRole = {
        ...state.userRole,
        role: action.payload.role.name,
        permissions: action.payload.permissions,
        company: action.payload.company,
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(loginUser.pending, (state) => {
        state.processing = true;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loggedIn = true;
        state.userData = {
          ...state.userData,
          value: action.payload,
          loading: false,
          isPasswordChangedAfterInitialLogin: action.payload
            .isPasswordChangedAfterInitialLogin as boolean,
        };
        state.processing = false;
        state.error = initialState.error;
      })
      .addCase(loginUser.rejected, (state, { payload }) => {
        state.error = payload;
        state.processing = false;
      })
      .addCase(forgotPassword.pending, (state) => {
        state.forgotPassword.processing = true;
      })
      .addCase(forgotPassword.fulfilled, (state) => {
        state.forgotPassword.processing = false;
        state.forgotPassword.error = initialState.error;
      })
      .addCase(forgotPassword.rejected, (state, { payload }) => {
        state.forgotPassword.error = payload;
        state.forgotPassword.processing = false;
      })
      .addCase(getMe.pending, (state) => {
        state.loading = true;
        state.userData.loading = true;
      })
      .addCase(getMe.fulfilled, (state, { payload }) => {
        state.loggedIn = true;
        state.loading = false;
        state.userData = {
          ...state.userData,
          value: payload,
          loading: false,
          error: initialState.userData.error,
          isPasswordChangedAfterInitialLogin:
            payload.isPasswordChangedAfterInitialLogin,
        };
      })
      .addCase(getMe.rejected, (state, { payload }) => {
        state.loading = false;
        state.userData = {
          ...state.userData,
          loading: false,
          error: payload,
        };
      })
      .addCase(updateMe.pending, (state) => {
        state.userData.processing = true;
      })
      .addCase(updateMe.fulfilled, (state, { payload }) => {
        state.userData = {
          ...state.userData,
          value: payload,
          processing: false,
          error: initialState.userData.error,
        };
      })
      .addCase(updateMe.rejected, (state, { payload }) => {
        state.userData.error = payload;
        state.userData.processing = false;
      })
      .addCase(resetPassword.pending, (state) => {
        state.userData.processing = true;
        state.userData.resetPass = false;
      })
      .addCase(resetPassword.fulfilled, (state) => {
        state.userData = {
          ...state.userData,
          processing: false,
          resetPass: true,
          error: initialState.userData.error,
        };
      })
      .addCase(resetPassword.rejected, (state, { payload }) => {
        state.userData.error = payload;
        state.userData.processing = false;
      })
      .addCase(handleLogout.pending, (state) => {
        state.loading = true;
      })
      .addCase(handleLogout.fulfilled, () => {
        return {
          ...initialState, // Reset everything to initial state
          loggedOut: true, // Update loggedOut to true
        };
      })
      .addCase(handleLogout.rejected, (state, { payload }) => {
        state.error = payload;
        state.loading = false;
      });
  },
});

export const {
  clearResetPass,
  clearForgotPassword,
  clearLoginError,
  changeAuthPassword,
  handleUserPermission,
} = authSlice.actions;

export default authSlice.reducer;
