import { ReactComponent as PlaceholderIco } from "assets/icons/text-emprty-state-ico.svg";
import { ReactNode } from "react";

type propsTypes = {
  className?: string;
  editable?: boolean;
  editBtn?: ReactNode;
};

const EmptyTextState = ({ className, editable, editBtn }: propsTypes) => {
  return (
    <div
      className={`bg-ultra-light-gray flex flex-col items-center justify-center rounded-xl w-full py-8 my-2 ${className}`}
    >
      <PlaceholderIco />
      <p className="text-gray font-light text-base mt-4 mb-1.5">
        No text here yet
      </p>
      {editable && editBtn && <div className="cursor-pointer">{editBtn}</div>}
    </div>
  );
};

export default EmptyTextState;
